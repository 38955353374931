import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Text } from '@chakra-ui/react';

import CustomMap from './customMap';
import ReactHelmet from '../../../../components/ReactHelmet';

const AtmLocator = () => {
  const [pageData, setPageData] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [websiteSettings, setWebsiteSettings] = useState([]);

  useEffect(() => {
    const getCareers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/atm-locations`
        );

        setPageData(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getWebsiteData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/website-settings`
        );

        setWebsiteSettings(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getWebsiteData();
    getCareers();
  }, []);

  return (
    <>
      <ReactHelmet title={'ATM Locator | Bank Enterprise'} />

      <Box
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        alignContent={'center'}
        backgroundSize={'cover'}
        justifyContent={'center'}
        backgroundRepeat={'no-repeat'}
        boxShadow={'3px 3px 200px #000 inset'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
        backgroundColor={`${
          websiteSettings?.background_images?.others_bg_color || '#e6e6e6'
        }`}
      >
        <Box
          flexDir={'row'}
          display={'flex'}
          gap={[4, 4, 5, 5, 5]}
          px={[5, 5, 5, 10, 10]}
          mt={[0, 0, 10, 10, 10]}
          justifyContent={'flex-end'}
        >
          <Box
            w={'100%'}
            display={'flex'}
            flexDir={'column'}
            gap={[5, 5, 5, 10, 10]}
            px={[0, 0, 0, 0, 0, 10]}
            mb={[0, 0, 0, 0, 0, 20]}
          >
            <Box
              w={'100%'}
              flexDir={'row'}
              display={'flex'}
              alignItems={'center'}
              alignContent={'center'}
              justifyContent={'center'}
              marginTop={[10, 10, 0, 0, 0]}
            >
              <Text
                width={'100%'}
                color={'black'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
                flex={[
                  '0 0 7rem',
                  '0 0 7rem',
                  '0 0 7rem',
                  '0 0 8rem',
                  '0 0 10rem',
                ]}
              >
                ATM Locator
              </Text>
              <Divider
                ml={4}
                w={'80%'}
                borderWidth={3}
                borderRadius={5}
                borderColor={'#E76D0C'}
                orientation={'horizontal'}
              />
            </Box>
            <Box
              p={10}
              gap={5}
              display={'flex'}
              borderRadius={10}
              backgroundColor={'white'}
              boxShadow={'0 50px 200px #000'}
              flexDirection={[
                'column-reverse',
                'column-reverse',
                'column-reverse',
                'row',
                'row',
              ]}
            >
              <Box flex={'1'} display={'flex'} flexDir={'column'}>
                {pageData.map((item) => {
                  return (
                    <Button
                      key={item.id}
                      paddingY={5}
                      width={'100%'}
                      display={'flex'}
                      borderRadius={0}
                      textAlign={'left'}
                      title={item.branch}
                      borderBottomWidth={2}
                      justifyContent={'start'}
                      backgroundColor={'white'}
                      fontSize={[14, 14, 15, 12, 14]}
                      onClick={() => setSelectedItem(item)}
                      whiteSpace={['unset', 'unset', 'unset', 'wrap', 'wrap']}
                    >
                      {item.address}
                    </Button>
                  );
                })}
              </Box>

              <div style={{ flex: '1', position: 'relative' }}>
                <CustomMap locations={selectedItem || {}} data={pageData} />
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default AtmLocator;
