import axios from 'axios';
import parse from 'html-react-parser';
import { useQuery } from 'react-query';
import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import {
  Box,
  Text,
  Image,
  Divider,
  useToast,
  ListItem,
  Textarea,
  UnorderedList,
} from '@chakra-ui/react';

import { getExecutives } from '../actions/actions';
import TopLinks from '../../../components/TopLinks';
import ReactHelmet from '../../../components/ReactHelmet';

import './styles.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const PageThree = () => {
  const { data, isLoading, isError, error } = useQuery(
    'executives',
    getExecutives
  );

  const toast = useToast();

  const [pageData, setPageData] = useState();
  const [websiteSettings, setWebsiteSettings] = useState([]);

  const handleExecutives = () => {
    if (data) {
      setPageData(data.data.data);
    }
    if (isError) {
      toast({
        title: 'Error',
        status: 'Error',
        duration: 10000,
        description: error,
        position: 'top-right',
      });
    }
  };

  const openLinkInNewTab = (url) => {
    var link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
  };

  const removeQuotes = (string) => {
    if (!string) return;

    return string.replace(/"/g, '');
  };

  useEffect(() => {
    setTimeout(() => {
      handleExecutives();
    }, 0);
  });

  useEffect(() => {
    console.log('pageData', pageData);
  }, [pageData]);

  useEffect(() => {
    const getWebsiteData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/website-settings`
        );

        setWebsiteSettings(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getWebsiteData();
  }, []);

  console.log('pageData', pageData);

  return (
    <>
      <ReactHelmet title={'Executives | Bank Enterprise'} />

      <Box
        gap={5}
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        justifyContent={'start'}
        boxShadow={'3px 3px 200px #000 inset'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
        background={
          websiteSettings?.background_images?.other_page_bg_type === 'color'
            ? websiteSettings?.background_images?.other_page_bg_value
            : `url('${websiteSettings?.background_images?.other_page_bg_value}')`
        }
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
      >
        <Box
          flexDir={'row'}
          display={'flex'}
          gap={[4, 4, 5, 5, 5]}
          px={[5, 5, 5, 10, 10]}
          mt={[0, 0, 10, 10, 10]}
          justifyContent={'flex-end'}
        >
          <TopLinks />
        </Box>
        <Box
          w={'100%'}
          flexDir={'row'}
          display={'flex'}
          px={[10, 10, 10, 20, 20]}
          justifyContent={'center'}
        >
          <Box
            w={'100%'}
            display={'flex'}
            flexDir={'column'}
            gap={[0, 0, 0, 10, 10]}
            px={[0, 0, 0, 0, 0, 10]}
            mb={[0, 0, 0, 0, 0, 20]}
          >
            <Box
              w={'100%'}
              flexDir={'row'}
              display={'flex'}
              textAlign={'start'}
              alignItems={'center'}
              alignContent={'flex-start'}
              justifyContent={'flex-start'}
            >
              <Text
                width={'100%'}
                color={'white'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
                flex={[
                  '0 0 10.5rem',
                  '0 0 10.5rem',
                  '0 0 10.5rem',
                  '0 0 12rem',
                  '0 0 14rem',
                ]}
              >
                Executives
              </Text>
              <Divider
                borderWidth={3}
                borderRadius={5}
                borderColor={'#E76D0C'}
                orientation={'horizontal'}
                w={['80%', '80%', '80%', '80%', '80%', '100%']}
                ml={4}
              />
            </Box>

            <Carousel
              autoPlay
              swipeable={true}
              interval={10000}
              showStatus={true}
              showArrows={true}
              emulateTouch={true}
              showIndicators={false}
              className='carousel-container'
            >
              {pageData &&
                pageData.length > 0 &&
                pageData.map((dd, idx) => {
                  const finaldescription = removeQuotes(dd?.description);

                  return (
                    <Box
                      gap={10}
                      key={idx}
                      display={'flex'}
                      flexDir={['column', 'column', 'column', 'row', 'row']}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        flex={[1, 1, 1, '0 0 15rem', '0 0 25rem']}
                      >
                        <Image
                          w={'100%'}
                          height={'auto'}
                          src={dd?.img_url}
                          borderRadius={10}
                          justifyContent={'center'}
                          marginTop={['2rem', '2rem', '2rem', 0, 0]}
                          maxHeight={['13rem', '13rem', 'none', 'none', 'none']}
                          objectFit={[
                            'cover',
                            'cover',
                            'contain',
                            'contain',
                            'contain',
                          ]}
                          maxWidth={[
                            '12rem',
                            '12rem',
                            '18rem',
                            '19rem',
                            '19rem',
                          ]}
                          // boxShadow={'10px 20px 40px #9c9c9c'}
                        />
                      </Box>
                      <Box
                        flex={1}
                        display={'flex'}
                        flexDir={'column'}
                        textAlign={'start'}
                        py={[0, 0, 0, 10, 10]}
                        justifyContent={'flex-start'}
                      >
                        <Text
                          color={'#E76D0C'}
                          fontFamily={'Poppins'}
                          fontSize={[18, 18, 20, 20, 25]}
                        >
                          {dd?.name}
                        </Text>
                        <Text
                          fontSize={14}
                          color={'#fff'}
                          fontFamily={'Poppins'}
                        >
                          {dd?.position}
                        </Text>
                        <Divider
                          w={'100%'}
                          borderWidth={1}
                          borderRadius={5}
                          borderColor={'#E76D0C'}
                          orientation={'horizontal'}
                        />

                        {/* <Text
                          fontSize={12}
                          marginTop={5}
                          color={'#fff'}
                          fontFamily={'Poppins'}
                        >
                          {finaldescription.map((dd, idx) => (
                            <span
                              style={{ display: 'block', marginBottom: '5px' }}
                              key={idx}
                            >
                              {dd}
                            </span>
                          ))}
                        </Text> */}

                        {dd?.description && (
                          <div
                            style={{ marginTop: '1rem', color: '#fff' }}
                            className='universal-description'
                          >
                            {parse(finaldescription)}
                          </div>
                        )}
                      </Box>
                    </Box>
                  );
                })}
            </Carousel>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PageThree;
