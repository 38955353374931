import axios from 'axios';
import { useQuery } from 'react-query';
import { Circles } from 'react-loader-spinner';
import React, { useEffect, useState } from 'react';
import {
  Td,
  Th,
  Tr,
  Box,
  Text,
  Tbody,
  Thead,
  Image,
  Table,
  Divider,
  useToast,
  TableContainer,
} from '@chakra-ui/react';

import { getProperties } from '../../actions/actions';
import ReactHelmet from '../../../../components/ReactHelmet';

const Properties = () => {
  const { data, isLoading, isError, error } = useQuery(
    'content',
    getProperties
  );

  const toast = useToast();

  const [properties, setProperties] = useState([]);
  const [websiteSettings, setWebsiteSettings] = useState([]);

  const handleProperties = () => {
    if (data) {
      setProperties(data.data.data);
    }
    if (isError) {
      toast({
        title: 'Error',
        status: 'Error',
        duration: 10000,
        description: error,
        position: 'top-right',
      });
    }
  };

  const handleImgClick = (img) => {
    if (img) {
      var newTab = window.open();
      newTab.document.write(
        '<html><body><img src="' + img + '" /></body></html>'
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleProperties();
    }, 0);
  });

  useEffect(() => {
    const getWebsiteData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/website-settings`
        );

        setWebsiteSettings(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getWebsiteData();
  }, []);

  return (
    <>
      <ReactHelmet title={'Properties | Bank Enterprise'} />

      <Box
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        alignContent={'center'}
        justifyContent={'center'}
        boxShadow={'3px 3px 200px #000 inset'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
        background={
          websiteSettings?.background_images?.other_page_bg_type === 'color'
            ? websiteSettings?.background_images?.other_page_bg_value
            : `url('${websiteSettings?.background_images?.other_page_bg_value}')`
        }
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
      >
        <Box
          flexDir={'row'}
          display={'flex'}
          gap={[4, 4, 5, 5, 5]}
          px={[5, 5, 5, 10, 10]}
          mt={[0, 0, 10, 10, 10]}
          justifyContent={'flex-end'}
        >
          <Box
            mb={10}
            gap={10}
            w={'100%'}
            display={'flex'}
            flexDir={'column'}
            px={[5, 5, 10, 10, 10]}
          >
            <Box
              w={'100%'}
              flexDir={'row'}
              display={'flex'}
              textAlign={'start'}
              alignItems={'center'}
              alignContent={'flex-start'}
              marginTop={[10, 10, 0, 0, 0]}
              justifyContent={'flex-start'}
            >
              <Text
                width={'100%'}
                color={'white'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
                flex={[
                  '0 0 12rem',
                  '0 0 12rem',
                  '0 0 12rem',
                  '0 0 13rem',
                  '0 0 16rem',
                ]}
              >
                Acquired Properties
              </Text>

              <Divider
                ml={4}
                w={'70%'}
                borderWidth={3}
                borderRadius={5}
                borderColor={'#E76D0C'}
                orientation={'horizontal'}
              />
            </Box>
            <Box
              p={5}
              gap={5}
              display={'flex'}
              borderRadius={10}
              flexDir={'column'}
              backgroundColor={'white'}
              boxShadow={'0 50px 200px #000'}
            >
              <TableContainer>
                <Table whiteSpace={'break-spaces'}>
                  <Thead>
                    <Tr>
                      <Th
                        fontSize={10}
                        color={'#E76D0C'}
                        fontFamily={'Poppins'}
                      >
                        Image
                      </Th>
                      <Th
                        fontSize={10}
                        color={'#E76D0C'}
                        fontFamily={'Poppins'}
                      >
                        Branch
                      </Th>
                      <Th
                        fontSize={10}
                        color={'#E76D0C'}
                        fontFamily={'Poppins'}
                      >
                        Location
                      </Th>
                      <Th
                        fontSize={10}
                        color={'#E76D0C'}
                        fontFamily={'Poppins'}
                      >
                        Classification
                      </Th>
                      <Th
                        fontSize={10}
                        color={'#E76D0C'}
                        fontFamily={'Poppins'}
                      >
                        Description
                      </Th>
                      <Th
                        fontSize={10}
                        color={'#E76D0C'}
                        fontFamily={'Poppins'}
                      >
                        Area
                      </Th>
                      <Th
                        fontSize={10}
                        color={'#E76D0C'}
                        fontFamily={'Poppins'}
                      >
                        Price
                      </Th>
                      <Th
                        fontSize={10}
                        color={'#E76D0C'}
                        fontFamily={'Poppins'}
                      >
                        Contact
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {isLoading ? (
                      <Circles
                        height='80'
                        width='80'
                        color='#E76D0C'
                        ariaLabel='circles-loading'
                        visible={true}
                      />
                    ) : (
                      <>
                        {properties?.map((item) => {
                          return (
                            <Tr>
                              <Td fontSize={8} fontFamily={'Poppins'}>
                                <Image
                                  width={'2rem'}
                                  margin={'auto'}
                                  height={'2rem'}
                                  cursor={'pointer'}
                                  src={item.img_url}
                                  onClick={() => handleImgClick(item.img_url)}
                                />
                              </Td>
                              <Td fontSize={8} fontFamily={'Poppins'}>
                                {item.branch}
                              </Td>
                              <Td fontSize={8} fontFamily={'Poppins'}>
                                {item.location}
                              </Td>
                              <Td fontSize={8} fontFamily={'Poppins'}>
                                {item.classification}
                              </Td>
                              <Td fontSize={8} fontFamily={'Poppins'}>
                                {item.description}
                              </Td>
                              <Td fontSize={8} fontFamily={'Poppins'}>
                                {item.area}
                              </Td>
                              <Td fontSize={8} fontFamily={'Poppins'}>
                                {item.price}
                              </Td>
                              <Td fontSize={8} fontFamily={'Poppins'}>
                                {item.contact}
                              </Td>
                            </Tr>
                          );
                        })}
                      </>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Properties;
