import React from 'react';
import parse from 'html-react-parser';
import { AiFillBank } from 'react-icons/ai';
import { Carousel } from 'react-responsive-carousel';
import {
  Box,
  Grid,
  Text,
  Image,
  Button,
  Divider,
  GridItem,
  Textarea,
} from '@chakra-ui/react';
import CustomTextArea from '../../../../../components/TextArea';

const MainView = ({ showScreen, pageData, setShowScreen, setSelectedData }) => {
  const handleSelect = (data) => {
    setShowScreen(1);
    setSelectedData(data);
  };

  const removeQuotes = (string) => {
    if (!string) return;

    return string.replace(/"/g, '');
  };

  return (
    <>
      <Box
        w={'100%'}
        flexDir={'row'}
        display={'flex'}
        alignItems={'center'}
        px={[5, 5, 5, 10, 10]}
        alignContent={'center'}
        justifyContent={'flex-start'}
        marginTop={[20, 20, 10, 10, 10]}
      >
        <Text
          color={'#E76D0C'}
          fontFamily={'Poppins'}
          fontSize={[18, 18, 18, 20, 25]}
        >
          Business Banking
        </Text>

        <Divider
          ml={4}
          w={'45%'}
          borderWidth={3}
          borderRadius={5}
          borderColor={'#E76D0C'}
          orientation={'horizontal'}
        />
      </Box>

      <Box
        w={'100%'}
        flexDir={'row'}
        display={'flex'}
        px={[5, 5, 5, 10, 10]}
        justifyContent={'center'}
      >
        <Carousel
          infiniteLoop
          swipeable={true}
          interval={10000}
          showStatus={true}
          showArrows={true}
          emulateTouch={true}
          showIndicators={false}
          className='carousel-container-2'
          autoPlay={showScreen === 0 ? true : false}
        >
          {pageData &&
            pageData.length > 0 &&
            pageData.map((dd) => {
              const description = removeQuotes(dd?.description);

              return (
                <Box display={'flex'} flexDir={'row'} gap={10}>
                  <Box
                    py={10}
                    w={'100%'}
                    gap={'1rem'}
                    display={'flex'}
                    textAlign={'start'}
                    justifyContent={'flex-start'}
                    flexDir={['column', 'column', 'row', 'row', 'row']}
                  >
                    <Box
                      flex={[
                        '0 0 10rem',
                        '0 0 10rem',
                        '0 0 15rem',
                        '0 0 20rem',
                        '0 0 25rem',
                      ]}
                      width={['16rem', '20rem', 'auto', 'auto', 'auto']}
                      margin={['auto', 'auto', 'unset', 'unset', 'unset']}
                    >
                      <Image w={'100%'} h={'auto'} src={dd?.img_path} />
                    </Box>

                    <Box flex={2}>
                      <Text
                        color={'#E76D0C'}
                        fontFamily={'Poppins'}
                        fontSize={[20, 20, 20, 25, 25]}
                      >
                        {dd?.title}
                      </Text>
                      <Text
                        fontSize={14}
                        color={'#1a202c'}
                        marginBottom={'5px'}
                        fontFamily={'Poppins'}
                      >
                        {dd?.subtitle}
                      </Text>
                      <Divider
                        w={'100%'}
                        borderWidth={1}
                        borderRadius={5}
                        borderColor={'#E76D0C'}
                        orientation={'horizontal'}
                      />

                      {/* 
                      <Text
                        my={5}
                        readOnly
                        spacing={5}
                        padding={'0'}
                        border={'none'}
                        color={'#1a202c'}
                        fontFamily={'Poppins'}
                        fontSize={[10, 10, 10, 10, 12, 15]}
                      >
                        {finaldescription.map((dd, idx) => (
                          <span
                            style={{ display: 'block', marginBottom: '5px' }}
                            key={idx}
                          >
                            {dd}
                          </span>
                        ))}
                      </Text> */}

                      {dd?.description && (
                        <div className='universal-description'>
                          {parse(description)}
                        </div>
                      )}

                      {/* <Button
                        color={'#fff'}
                        backgroundColor={'#E76D0C'}
                        onClick={() => handleSelect(dd)}
                        fontSize={[14, 14, 14, 16, 16, 16]}
                        width={['8rem', '8rem', '10rem', '10rem', '10rem']}
                      >
                        View Services
                      </Button> */}
                    </Box>
                  </Box>
                </Box>
              );
            })}
        </Carousel>
      </Box>
    </>
  );
};

export default MainView;
