import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Divider,
  Image,
  Text,
  useToast,
  Textarea,
} from '@chakra-ui/react';

import TopLinks from '../../../components/TopLinks';
import ReactHelmet from '../../../components/ReactHelmet';
import { getWebsiteSettings } from '../../About/actions/actions';

import map from '../../../assets/map.png';
import left from '../../../assets/left.png';
import right from '../../../assets/right.png';
import meeting from '../../../assets/meeting.png';
import messenger from '../../../assets/messenger.png';

const PageTwo = () => {
  const { data, isLoading, isError, error } = useQuery(
    'websiteSettings',
    getWebsiteSettings
  );

  const toast = useToast();

  const [pageData, setPageData] = useState(null);

  const handleExecutives = () => {
    if (data) {
      setPageData(data.data.data);
    }
    if (isError) {
      toast({
        title: 'Error',
        status: 'Error',
        duration: 10000,
        description: error,
        position: 'top-right',
      });
    }
  };

  const handleDescription = (description) => {
    const desData = description.split('\n');
    const finalDescriptionData = desData.filter((dd) => dd !== '\r');

    return (
      <Text
        my={3}
        mt={5}
        color={'white'}
        fontFamily={'Poppins'}
        fontSize={[11, 11, 11, 13, 13]}
      >
        {finalDescriptionData.map((ddd, idx) => (
          <span style={{ display: 'block', marginBottom: '10px' }} key={idx}>
            {ddd}
          </span>
        ))}
      </Text>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      handleExecutives();
    }, 0);
  });

  return (
    <>
      <ReactHelmet title={'What We Do | Bank Enterprise'} />

      <Box
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        backgroundRepeat={'no-repeat'}
        justifyContent={'space-between'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
        background={
          pageData?.background_images?.other_page_bg_type === 'color'
            ? pageData?.background_images?.other_page_bg_value
            : `url('${pageData?.background_images?.other_page_bg_value}')`
        }
        backgroundSize={'cover'}
      >
        <Box
          flexDir={'row'}
          display={'flex'}
          gap={[4, 4, 5, 5, 5]}
          px={[5, 5, 5, 10, 10]}
          mt={[0, 0, 10, 10, 10]}
          justifyContent={'flex-end'}
        >
          <TopLinks />
        </Box>
        <Box
          w={'100%'}
          display={'flex'}
          gap={[4, 4, 3, 3, 3]}
          px={[5, 5, 5, 10, 10]}
          marginTop={[10, 10, 0, 0, 0]}
          justifyContent={'center'}
          flexDir={['column-reverse', 'column-reverse', 'row', 'row', 'row']}
        >
          <Box
            mb={0}
            flex={1}
            w={'100%'}
            display={'flex'}
            flexDir={'column'}
            px={[0, 0, 0, 0, 0, 10]}
          >
            <Box
              w={'100%'}
              flexDir={'row'}
              display={'flex'}
              textAlign={'start'}
              alignItems={'center'}
              alignContent={'flex-start'}
              justifyContent={'flex-start'}
            >
              <Text
                color={'white'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
                w={['16rem', '16rem', '15rem', '15rem', '16rem']}
              >
                Banking that
              </Text>

              <Divider
                ml={4}
                w={'100%'}
                borderWidth={3}
                borderRadius={5}
                borderColor={'#E76D0C'}
                orientation={'horizontal'}
              />
            </Box>

            <Text
              color={'#E76D0C'}
              fontFamily={'Poppins'}
              fontSize={[16, 16, 18, 18, 20]}
            >
              Fits your needs
            </Text>

            {pageData && pageData.home_page_details && (
              <>{handleDescription(pageData?.home_page_details?.what_we_do)}</>
            )}
          </Box>

          {/* <Textarea
                my={3}
                mt={5}
                readOnly
                rows={15}
                padding={0}
                fontSize={[11, 11, 11, 13, 13]}
                color={'white'}
                border={'none'}
                fontFamily={'Poppins'}
                >
                {pageData?.home_page_details?.what_we_do}
                </Textarea> */}

          {pageData &&
            pageData.home_page_details &&
            pageData.home_page_details.what_we_do_banner && (
              <Box flex={1}>
                <Image
                  mx={10}
                  margin={'0 auto'}
                  borderRadius={10}
                  objectFit={'cover'}
                  w={['auto', 'auto', 'auto', 'auto', 'auto']}
                  src={pageData?.home_page_details?.what_we_do_banner}
                  height={['10rem', '10rem', '10rem', '13rem', '20rem']}
                  // maxW={['15rem', '15rem', '20rem', '20rem', '40rem']}
                />
              </Box>
            )}
        </Box>
        <Box
          my={10}
          px={10}
          display={'flex'}
          letterSpacing={1}
          gap={[0, 2, 3, 3, 3]}
          alignItems={'center'}
          justifyContent={'flex-end'}
          flexDir={['column', 'column', 'row', 'row', 'row']}
        >
          <Text
            color={'#E76D0C'}
            fontFamily={'Poppins'}
            fontSize={[20, 20, 20, 30, 30]}
          >
            WHAT WE DO
          </Text>

          <Box display={'flex'} letterSpacing={1} gap={[2, 2, 3, 3, 3]}>
            <Link to={'/'}>
              <Image
                src={left}
                mt={[10, 10, 0, 0, 0]}
                w={['30px', '30px', '30px', '40px', '40px']}
              />
            </Link>
            <Link to={'/products'}>
              <Image
                src={right}
                mt={[10, 10, 0, 0, 0]}
                w={['30px', '30px', '30px', '40px', '40px']}
              />
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PageTwo;
