import axios from 'axios';
import { useQuery } from 'react-query';
import React, { useState, useEffect } from 'react';
import { Box, Text, Divider, useToast, Textarea } from '@chakra-ui/react';

import { getWebsiteSettings } from '../../../actions/actions';
import ReactHelmet from '../../../../../components/ReactHelmet';
import CustomTextArea from '../../../../../components/TextArea';

const Terms = () => {
  const { data, isLoading, isError, error } = useQuery(
    'websiteSettings',
    getWebsiteSettings
  );

  const toast = useToast();

  const [pageData, setPageData] = useState(null);

  const handleTerms = () => {
    if (data) {
      setPageData(data.data.data);
    }
    if (isError) {
      toast({
        title: 'Error',
        status: 'Error',
        duration: 10000,
        description: error,
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleTerms();
    }, 0);
  });

  return (
    <>
      <ReactHelmet title={'Terms of Use | Bank Enterprise'} />

      <Box
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        alignContent={'center'}
        justifyContent={'flex-start'}
        boxShadow={'3px 3px 200px #000 inset'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
        background={
          pageData?.background_images?.other_page_bg_type === 'color'
            ? pageData?.background_images?.other_page_bg_value
            : `url('${pageData?.background_images?.other_page_bg_value}')`
        }
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
      >
        <Box
          flexDir={'row'}
          display={'flex'}
          gap={[4, 4, 5, 5, 5]}
          px={[5, 5, 5, 10, 10]}
          mt={[0, 0, 10, 10, 10]}
          justifyContent={'flex-end'}
        >
          <Box
            w={'100%'}
            display={'flex'}
            flexDir={'column'}
            gap={[5, 5, 5, 10, 10]}
            px={[0, 0, 0, 0, 0, 10]}
            mb={[0, 0, 0, 0, 0, 20]}
          >
            <Box
              w={'100%'}
              flexDir={'row'}
              display={'flex'}
              alignItems={'center'}
              alignContent={'center'}
              justifyContent={'center'}
              marginTop={[10, 10, 0, 0, 0]}
            >
              <Text
                width={'100%'}
                color={'black'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
                flex={[
                  '0 0 8rem',
                  '0 0 8rem',
                  '0 0 8rem',
                  '0 0 9rem',
                  '0 0 11rem',
                ]}
              >
                Terms of Use
              </Text>
              <Divider
                ml={4}
                w={'75%'}
                borderWidth={3}
                borderRadius={5}
                borderColor={'#E76D0C'}
                orientation={'horizontal'}
              />
            </Box>
            <Box
              gap={5}
              h={'auto'}
              display={'flex'}
              borderRadius={10}
              flexDir={'column'}
              p={[5, 5, 5, 10, 10]}
              backgroundColor={'white'}
              boxShadow={'0 50px 200px #000'}
            >
              <Text fontFamily={'Poppins'} fontSize={25} color={'#E76D0C'}>
                Terms of Use
              </Text>

              {pageData && pageData?.company_info && (
                <CustomTextArea
                  readOnly
                  times={1.3}
                  padding={0}
                  fontSize={12}
                  border={'none'}
                  fontFamily={'Poppins'}
                >
                  {pageData?.company_info?.terms_of_use}
                </CustomTextArea>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Terms;
