import axios from 'axios';
import parse from 'html-react-parser';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { AiOutlineShop } from 'react-icons/ai';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Image,
  Modal,
  Button,
  Divider,
  useToast,
  Textarea,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react';

import TopLinks from '../../../components/TopLinks';
import ReactHelmet from '../../../components/ReactHelmet';
import { getPersonalBankings } from '../../About/actions/actions';

import left from '../../../assets/left.png';
import right from '../../../assets/right.png';

const PageThree = () => {
  const { data, isLoading, isError, error } = useQuery(
    'personalBankings',
    getPersonalBankings
  );

  const toast = useToast();

  const [pageData, setPageData] = useState(null);
  const [websiteSettings, setWebsiteSettings] = useState([]);
  const [modalData, setModalData] = useState({
    data: null,
    isOpen: false,
  });

  const handlePersonalBanking = () => {
    if (data) {
      setPageData(data.data.data);
    }
    if (isError) {
      toast({
        title: 'Error',
        status: 'Error',
        duration: 10000,
        description: error,
        position: 'top-right',
      });
    }
  };

  const removeQuotesAndParse = (string) => {
    if (!string) return;

    const newString = string.replace(/"/g, '');

    return parse(newString);
  };

  const removeQuotes = (string) => {
    if (!string) return;

    return string.replace(/"/g, '');
  };

  const shortenText = (text) => {
    if (!text) return;

    if (text.length <= 30) {
      return text;
    } else {
      return `${text.substring(0, 30)}...`;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handlePersonalBanking();
    }, 0);
  });

  useEffect(() => {
    const getWebsiteData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/website-settings`
        );

        setWebsiteSettings(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getWebsiteData();
  }, []);

  return (
    <>
      <ReactHelmet title={'Our Products | Bank Enterprise'} />

      <Box
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        justifyContent={'space-between'}
        boxShadow={'3px 3px 200px #000 inset'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
        background={
          websiteSettings?.background_images?.other_page_bg_type === 'color'
            ? websiteSettings?.background_images?.other_page_bg_value
            : `url('${websiteSettings?.background_images?.other_page_bg_value}')`
        }
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
      >
        <Box
          flexDir={'row'}
          display={'flex'}
          gap={[4, 4, 5, 5, 5]}
          px={[5, 5, 5, 10, 10]}
          mt={[0, 0, 10, 10, 10]}
          justifyContent={'flex-end'}
        >
          <TopLinks />
        </Box>
        <Box
          px={10}
          w={'100%'}
          flexDir={'row'}
          display={'flex'}
          justifyContent={'center'}
        >
          <Box display={'flex'} flexDir={'column'} w={'100%'} px={10} mb={10}>
            <Box
              w={'100%'}
              gap={'1rem'}
              flexDir={'row'}
              display={'flex'}
              flexWrap={'wrap'}
              alignItems={'center'}
              alignContent={'center'}
              justifyContent={'center'}
            >
              <Text
                color={'white'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
              >
                Banking that{' '}
              </Text>

              <Text
                color={'#E76D0C'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
              >
                includes You
              </Text>
              <Divider
                ml={4}
                w={'45%'}
                borderWidth={3}
                borderRadius={5}
                borderColor={'#E76D0C'}
                orientation={'horizontal'}
              />
            </Box>
            <Box
              my={10}
              gap={15}
              w={'100%'}
              flexWrap={'wrap'}
              display={'inline-flex'}
              justifyContent={'center'}
            >
              {pageData &&
                pageData.length > 0 &&
                pageData.map((dd) => {
                  const finaldescription = removeQuotes(dd?.description);

                  return (
                    <Box
                      p={5}
                      gap={5}
                      display={'flex'}
                      borderRadius={10}
                      cursor={'pointer'}
                      flexDirection={'column'}
                      backgroundColor={'white'}
                      justifyContent={'flex-start'}
                      w={[200, 200, 200, 200, 250, 300]}
                      boxShadow={'10px 20px 40px #9c9c9c'}
                      onClick={() => {
                        setModalData({
                          data: dd,
                          isOpen: true,
                        });
                      }}
                    >
                      <AiOutlineShop size={30} color={'#E76D0C'} />
                      <Text
                        fontSize={15}
                        fontWeight={'medium'}
                        fontFamily={'Poppins'}
                      >
                        {dd?.title}
                      </Text>
                      {/* <Text fontFamily={'Poppins'} color={'gray'} fontSize={12}>
                        {shortenText(dd?.description)}
                      </Text> */}

                      {dd?.description && (
                        <div
                          style={{ fontSize: '12px', color: 'gray' }}
                          className='universal-description'
                        >
                          {parse(finaldescription)}
                        </div>
                      )}
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
        <Box
          my={10}
          px={10}
          display={'flex'}
          letterSpacing={1}
          gap={[0, 2, 3, 3, 3]}
          alignItems={'center'}
          justifyContent={'flex-end'}
          flexDir={['column', 'column', 'row', 'row', 'row']}
        >
          <Text
            color={'#E76D0C'}
            fontFamily={'Poppins'}
            fontSize={[20, 20, 20, 30, 30]}
          >
            OUR PRODUCTS
          </Text>

          <Box display={'flex'} letterSpacing={1} gap={[2, 2, 3, 3, 3]}>
            <Link to={'/whatwedo'}>
              <Image
                src={left}
                mt={[10, 10, 0, 0, 0]}
                w={['30px', '30px', '30px', '40px', '40px']}
              />
            </Link>
            <Link to={'/offers'}>
              <Image
                src={right}
                mt={[10, 10, 0, 0, 0]}
                w={['30px', '30px', '30px', '40px', '40px']}
              />
            </Link>
          </Box>
        </Box>
      </Box>

      <Modal
        isOpen={modalData?.isOpen}
        onClose={() => {
          setModalData({
            data: null,
            isOpen: false,
          });
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalData?.data?.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Textarea
              readOnly
              rows={20}
              spacing={5}
              padding={'0'}
              fontSize={12}
              color={'gray'}
              border={'none'}
              fontFamily={'Poppins'}
            >
              {modalData?.data?.description || 'No description'}
            </Textarea> */}
            {modalData?.data?.description && (
              <div
                style={{ fontSize: '12px', color: 'gray' }}
                className='universal-description'
              >
                {removeQuotesAndParse(modalData?.data?.description)}
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              mb={2}
              fontSize={14}
              width={'6rem'}
              color={'#fff'}
              display={'flex'}
              justifyContent={'center'}
              backgroundColor={'#E76D0C'}
              onClick={() => {
                setModalData({
                  data: null,
                  isOpen: false,
                });
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PageThree;
