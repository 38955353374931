import { BsBank } from 'react-icons/bs';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { HiBanknotes } from 'react-icons/hi2';
import { FaRegCalendarAlt } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import { Box, Divider, Image, Text, useToast } from '@chakra-ui/react';

import TopLinks from '../../../components/TopLinks';
import ReactHelmet from '../../../components/ReactHelmet';
import { getWebsiteSettings } from '../../About/actions/actions';

import left from '../../../assets/left.png';
import right from '../../../assets/right.png';

const PageSix = () => {
  const { data, isLoading, isError, error } = useQuery(
    'websiteSettings',
    getWebsiteSettings
  );

  const toast = useToast();

  const [pageData, setPageData] = useState(null);

  const handleExecutives = () => {
    if (data) {
      setPageData(data.data.data);
    }
    if (isError) {
      toast({
        title: 'Error',
        status: 'Error',
        duration: 10000,
        description: error,
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleExecutives();
    }, 0);
  });

  return (
    <>
      <ReactHelmet title={'Milestones | Bank Enterprise'} />

      <Box
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        justifyContent={'space-between'}
        boxShadow={'3px 3px 200px #000 inset'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
        background={
          pageData?.background_images?.other_page_bg_type === 'color'
            ? pageData?.background_images?.other_page_bg_value
            : `url('${pageData?.background_images?.other_page_bg_value}')`
        }
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
      >
        <Box
          flexDir={'row'}
          display={'flex'}
          gap={[4, 4, 5, 5, 5]}
          px={[5, 5, 5, 10, 10]}
          mt={[0, 0, 10, 10, 10]}
          justifyContent={'flex-end'}
        >
          <TopLinks />
        </Box>
        <Box
          w={'100%'}
          flexDir={'row'}
          display={'flex'}
          px={[5, 5, 5, 10, 10]}
          justifyContent={'center'}
        >
          <Box display={'flex'} flexDir={'column'} w={'100%'} px={10} mb={10}>
            <Box
              w={'100%'}
              gap={'1rem'}
              flexDir={'row'}
              display={'flex'}
              flexWrap={'wrap'}
              alignItems={'center'}
              alignContent={'center'}
              justifyContent={'center'}
            >
              <Text
                color={'white'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
              >
                Your Success is also
              </Text>
              &nbsp;
              <Text
                color={'#E76D0C'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
              >
                Our Success
              </Text>
              <Divider
                ml={4}
                w={'41%'}
                borderWidth={3}
                borderRadius={5}
                borderColor={'#E76D0C'}
                orientation={'horizontal'}
              />
            </Box>
            <Box
              my={10}
              gap={15}
              w={'100%'}
              flexWrap={'wrap'}
              display={'inline-flex'}
              justifyContent={'center'}
            >
              <Box
                p={5}
                display={'flex'}
                color={'#E76D0C'}
                borderRadius={10}
                textAlign={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                justifyContent={'center'}
                backgroundColor={'white'}
                w={[200, 200, 200, 300, 300]}
              >
                <Box
                  height={'auto'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  width={[70, 70, 70, 70, 70]}
                >
                  <FaRegCalendarAlt className='card-image' />
                </Box>

                {pageData && pageData?.milestones && (
                  <Text
                    fontWeight={'bold'}
                    fontFamily={'Poppins'}
                    fontSize={[25, 25, 30, 40, 55]}
                  >
                    {pageData?.milestones?.exp_yrs}
                  </Text>
                )}

                <Text
                  fontFamily={'Poppins'}
                  color={'black'}
                  fontSize={[18, 18, 20, 25, 25]}
                >
                  Years of Experience
                </Text>
              </Box>
              <Box
                p={5}
                display={'flex'}
                color={'#E76D0C'}
                borderRadius={10}
                textAlign={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                justifyContent={'center'}
                backgroundColor={'white'}
                w={[200, 200, 200, 300, 300]}
              >
                <Box
                  height={'auto'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  width={[70, 70, 70, 70, 70]}
                >
                  <BsBank className='card-image' />
                </Box>

                {pageData && pageData?.milestones && (
                  <Text
                    fontWeight={'bold'}
                    fontFamily={'Poppins'}
                    fontSize={[25, 25, 30, 40, 55]}
                  >
                    {pageData?.milestones?.banking_accounts}
                  </Text>
                )}

                <Text
                  fontFamily={'Poppins'}
                  color={'black'}
                  fontSize={[18, 18, 20, 25, 25]}
                >
                  Banking Accounts
                </Text>
              </Box>
              <Box
                p={5}
                display={'flex'}
                color={'#E76D0C'}
                borderRadius={10}
                textAlign={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                backgroundColor={'white'}
                justifyContent={'center'}
                w={[200, 200, 200, 300, 300]}
              >
                <Box
                  height={'auto'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  width={[70, 70, 70, 70, 70]}
                >
                  <HiBanknotes className='card-image' />
                </Box>

                {pageData && pageData?.milestones && (
                  <Text
                    fontWeight={'bold'}
                    fontFamily={'Poppins'}
                    fontSize={[25, 25, 30, 40, 55]}
                  >
                    {pageData?.milestones?.loan_clients}
                  </Text>
                )}
                <Text
                  fontFamily={'Poppins'}
                  color={'black'}
                  fontSize={[18, 18, 20, 25, 25]}
                >
                  Loan Clients
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          my={10}
          px={10}
          display={'flex'}
          letterSpacing={1}
          gap={[0, 2, 3, 3, 3]}
          alignItems={'center'}
          justifyContent={'flex-end'}
          flexDir={['column', 'column', 'row', 'row', 'row']}
        >
          <Text
            color={'#E76D0C'}
            fontFamily={'Poppins'}
            fontSize={[20, 20, 20, 30, 30]}
          >
            OTHER MILESTONES
          </Text>

          <Box display={'flex'} letterSpacing={1} gap={[2, 2, 3, 3, 3]}>
            <Link to={'/services'}>
              <Image
                src={left}
                mt={[10, 10, 0, 0, 0]}
                w={['30px', '30px', '30px', '40px', '40px']}
              />
            </Link>
            <Link to={'/feedbacks'}>
              <Image
                src={right}
                mt={[10, 10, 0, 0, 0]}
                w={['30px', '30px', '30px', '40px', '40px']}
              />
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PageSix;
