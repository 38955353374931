import React from 'react';
import {
  Box,
  Text,
  Grid,
  Card,
  Image,
  Button,
  Divider,
  CardBody,
  GridItem,
  CardFooter,
  CardHeader,
} from '@chakra-ui/react';

import sampleImage from '../../../../../assets/coin.png';

const ViewProduct = ({ selectedData, setSelectedData, setShowScreen }) => {
  const handleBack = () => {
    setSelectedData(null);
    setShowScreen(0);
  };

  console.log('selectedData', selectedData);
  // return;

  return (
    <Box
      w='100%'
      display='flex'
      flexDir='column'
      alignItems='center'
      alignContent='center'
      justifyContent='center'
    >
      <Box
        w='100%'
        flexDir='row'
        display='flex'
        alignItems='center'
        alignContent='center'
        justifyContent='center'
        marginTop={[20, 20, 0, 0, 0]}
      >
        <Text
          display='flex'
          color='#E76D0C'
          alignItems='center'
          fontFamily='Poppins'
          fontSize={[18, 18, 18, 20, 25]}
        >
          <Button
            padding='0'
            height='auto'
            border='none'
            minWidth='30px'
            marginRight='5px'
            fontSize='1.2rem'
            onClick={handleBack}
            background='transparent'
          >
            &#60;
          </Button>

          {selectedData?.title}
        </Text>
        <Divider
          ml={4}
          w='45%'
          borderWidth={3}
          borderRadius={5}
          borderColor='#E76D0C'
          orientation='horizontal'
        />
      </Box>
      <Box
        my={5}
        gap={15}
        w='100%'
        flexWrap='wrap'
        display='inline-flex'
        justifyContent='center'
      >
        <Grid
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
          ]}
          gap={5}
        >
          {selectedData &&
            selectedData?.services.map((item) => {
              const hasTerms = item?.terms !== '';
              const havePrice = item?.price !== '';
              const haveDescription = item?.description !== '';
              const hasImage = item?.img_path !== null;

              return (
                <GridItem w='100%'>
                  <Card
                    p={0}
                    height='100%'
                    w={['300px', '300px', '220px', '220px', '220px']}
                  >
                    <CardHeader
                      p={2}
                      m={0}
                      gap={2}
                      display='flex'
                      flexDir='column'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <Image
                        width={'auto'}
                        height={'2.5rem'}
                        alt={item?.title}
                        src={item?.img_path || sampleImage}
                      />

                      <Text fontFamily='Poppins' fontSize={12}>
                        {item.title}
                      </Text>
                    </CardHeader>

                    {(havePrice || hasTerms) && (
                      <CardBody
                        py={4}
                        px={2}
                        gap={3}
                        display='flex'
                        flexDir='column'
                        textAlign='center'
                      >
                        <Text fontFamily='Poppins-Bold' fontSize={12}>
                          {item.price}
                        </Text>

                        {hasTerms && (
                          <Box
                            gap={1}
                            display='flex'
                            flexDir='column'
                            alignItems='center'
                            justifyContent='center'
                          >
                            <Text fontFamily='Poppins' fontSize={10}>
                              {item?.terms}
                            </Text>

                            <Divider borderColor='black' w='65%' />
                          </Box>
                        )}
                      </CardBody>
                    )}

                    {haveDescription && (
                      <CardFooter display='flex' flexDir='column'>
                        <Text
                          fontSize={8}
                          color='gray'
                          flexWrap='wrap'
                          fontFamily='Poppins'
                        >
                          {item.description}
                        </Text>
                      </CardFooter>
                    )}
                  </Card>
                </GridItem>
              );
            })}
        </Grid>
      </Box>
    </Box>
  );
};

export default ViewProduct;
