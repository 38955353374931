import React, { useState, useEffect, useRef } from 'react';
import { Textarea } from '@chakra-ui/react';

const CustomTextArea = ({ children, times = 1, ...rest }) => {
  const textRef = useRef(null);

  const [rows, setRows] = useState(10);

  useEffect(() => {
    const changeHeight = () => {
      if (children) {
        const dataLength = children.split('\n').length;

        setRows(
          dataLength > 15 ? parseFloat(dataLength) * parseFloat(times) : 15
        );
      }
    };

    changeHeight();
  }, [children]);

  return (
    <Textarea {...rest} minRows rows={rows} ref={textRef}>
      {children}
    </Textarea>
  );
};

export default CustomTextArea;
