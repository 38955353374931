import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Grid,
  GridItem,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

import { GiTwoCoins } from "react-icons/gi";
import { useQuery } from "react-query";
import { getDeposits } from "../actions/actions";
import { Circles } from "react-loader-spinner";

// const data = [
//   {
//     title: "Super Checking Account",
//     type: "Individual",
//     terms: ["P 5000 Initial Deposit", "With Free Passbook and ATM"],
//   },
//   {
//     title: "Regular Savings",
//     type: "Individual",
//     terms: ["P 200 Initial Deposit", "0.1% Interest P.A", "With EMV ATM Card"],
//   },
//   {
//     title: "Regular Savings",
//     type: "Corporate",
//     terms: ["P 200 Initial Deposit", "0.1% Interest P.A", "With EMV ATM Card"],
//   },
//   {
//     title: "Regular Checking",
//     type: "Individual",
//     terms: ["P 3500 Initial Deposit", "With ATM Free of Charge"],
//   },
//   {
//     title: "Regular Checking",
//     type: "Corporate",
//     terms: ["P 10000 Initial Deposit", "With ATM Free of Charge"],
//   },
//   {
//     title: "Other Deposit Products",
//     type: "Time Deposit",
//     terms: [
//       "P 5000 Initial Deposit",
//       "Flexible Term",
//       "Variable Interest Rate",
//     ],
//   },
//   {
//     title: "Other Deposit Products",
//     type: "Long Term Deposit",
//     terms: ["P 500,000 Initial Deposit", "5 Years Term", "Tax-Exempt"],
//   },
// ];

const Deposits = () => {
  const toast = useToast();
  const [deposits, setDeposits] = useState([]);
  const { data, isLoading, error, isError } = useQuery("deposits", getDeposits);

  useEffect(() => {
    if (data) {
      setDeposits(data?.data?.deposits);
    }
    if (!data) {
      toast({
        title: "No data!",
        description: "No Data Received",
        status: "warning",
        duration: 3000,
        position: "top-right",
      });
    }
    if (isError) {
      toast({
        title: "Error",
        description: error,
        status: "Error",
        duration: 10000,
        position: "top-right",
      });
    }
  }, [data, error, toast, isError]);
  return (
    <>
      <Box
        w={"80%"}
        h={"768px"}
        backgroundColor={"#e6e6e6"}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        display={"flex"}
        flexDir={"column"}
        justifyContent={"space-between"}
        boxShadow={"3px 3px 200px #000 inset"}
      >
        <Box
          display={"flex"}
          flexDir={"row"}
          justifyContent={"center"}
          w={"100%"}
          p={10}
        >
          <Box display={"flex"} flexDir={"column"} w={"100%"}>
            <Box
              display={"flex"}
              flexDir={"row"}
              alignItems={"center"}
              alignContent={"center"}
              w={"100%"}
              justifyContent={"center"}
            >
              <Text fontFamily={"Poppins"} color={"#E76D0C"} fontSize={25}>
                Deposits
              </Text>
              <Divider
                orientation={"horizontal"}
                borderColor={"#E76D0C"}
                borderWidth={3}
                borderRadius={5}
                w={"45%"}
                ml={4}
              />
            </Box>
            <Box
              display={"inline-flex"}
              justifyContent={"center"}
              gap={15}
              my={5}
              w={"100%"}
              flexWrap={"wrap"}
            >
              <Grid templateColumns={"repeat(3, 1fr)"} gap={5}>
                {isLoading ? (
                  <Circles
                    height="80"
                    width="80"
                    color="#E76D0C"
                    ariaLabel="circles-loading"
                    visible={true}
                  />
                ) : (
                  <>
                    {deposits?.map((item) => {
                      return (
                        <GridItem w="100%">
                          <Card p={0} w={"220px"}>
                            <CardHeader
                              p={2}
                              display={"flex"}
                              flexDir={"column"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              gap={2}
                              m={0}
                            >
                              <GiTwoCoins size={32} color={"#E76D0C"} />
                              <Text fontFamily={"Poppins"} fontSize={12}>
                                {item.title}
                              </Text>
                            </CardHeader>
                            <CardBody
                              display={"flex"}
                              flexDir={"column"}
                              textAlign={"center"}
                              py={4}
                              px={2}
                              gap={3}
                            >
                              <Text fontFamily={"Poppins-Bold"} fontSize={12}>
                                {item.type}
                              </Text>
                              <Box
                                display={"flex"}
                                flexDir={"column"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                gap={1}
                              >
                                <Text fontFamily={"Poppins"} fontSize={10}>
                                  {item?.terms[0]}
                                </Text>
                                <Divider borderColor={"black"} w={"65%"} />
                                <Text fontFamily={"Poppins"} fontSize={10}>
                                  {item?.terms[1]}
                                </Text>
                                {item.terms[2] ? (
                                  <>
                                    <Divider borderColor={"black"} w={"65%"} />
                                    <Text fontFamily={"Poppins"} fontSize={10}>
                                      {item?.terms[2]}
                                    </Text>
                                  </>
                                ) : null}
                              </Box>
                            </CardBody>
                          </Card>
                        </GridItem>
                      );
                    })}
                  </>
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Deposits;
