import React from 'react';
import { Route, Routes } from 'react-router-dom';

import MainNav from './components/mainNav';
import PageTwo from './components/pageTwo';
import PageOne from './components/pageOne';
import PageSix from './components/pageSix';
import PageFive from './components/pagefive';
import PageFour from './components/pageFour';
import PageSeven from './components/pageSeven';
import PageThree from './components/pageThree';
import PersonalBanking from './components/PersonalBanking/PersonalBanking';
import BusinessBanking from './components/BusinessBanking/BusinessBanking';

const Home = () => {
  return (
    <>
      <Routes>
        <Route index element={<PageOne />} />
        {/* <Route path={'/menu'} element={<MainNav />} /> */}
        <Route path={'/personal-banking'} element={<PersonalBanking />} />
        <Route path={'/business-banking'} element={<BusinessBanking />} />
        <Route path={'/whatwedo'} element={<PageTwo />} />
        <Route path={'/products'} element={<PageThree />} />
        <Route path={'/offers'} element={<PageFour />} />
        <Route path={'/services'} element={<PageFive />} />
        <Route path={'/milestones'} element={<PageSix />} />
        <Route path={'/feedbacks'} element={<PageSeven />} />
      </Routes>
    </>
  );
};

export default Home;
