import axios from 'axios';
import parse from 'html-react-parser';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Divider,
  ListItem,
  Textarea,
  UnorderedList,
} from '@chakra-ui/react';

import ReactHelmet from '../../../../components/ReactHelmet';

import '../styles.scss';

const Careers = () => {
  const [pageData, setPageData] = useState([]);
  const [websiteSettings, setWebsiteSettings] = useState([]);

  const removeQuotes = (string) => {
    if (!string) return;

    return string.replace(/"/g, '');
  };

  useEffect(() => {
    const getCareers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/careers`
        );

        setPageData(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getWebsiteData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/website-settings`
        );

        setWebsiteSettings(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getCareers();
    getWebsiteData();
  }, []);

  return (
    <>
      <ReactHelmet title={'Careers | Bank Enterprise'} />

      <Box
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        alignContent={'center'}
        justifyContent={'center'}
        boxShadow={'3px 3px 200px #000 inset'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
        background={
          websiteSettings?.background_images?.other_page_bg_type === 'color'
            ? websiteSettings?.background_images?.other_page_bg_value
            : `url('${websiteSettings?.background_images?.other_page_bg_value}')`
        }
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
      >
        <Box
          flexDir={'row'}
          display={'flex'}
          gap={[4, 4, 5, 5, 5]}
          px={[5, 5, 5, 10, 10]}
          mt={[0, 0, 10, 10, 10]}
          justifyContent={'flex-end'}
        >
          <Box
            w={'100%'}
            display={'flex'}
            flexDir={'column'}
            gap={[5, 5, 5, 10, 10]}
            px={[0, 0, 0, 0, 0, 10]}
            mb={[0, 0, 0, 0, 0, 20]}
          >
            <Box
              w={'100%'}
              flexDir={'row'}
              display={'flex'}
              textAlign={'start'}
              alignItems={'center'}
              alignContent={'flex-start'}
              marginTop={[10, 10, 0, 0, 0]}
              justifyContent={'flex-start'}
            >
              <Text
                width={'100%'}
                color={'black'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
                flex={[
                  '0 0 2rem',
                  '0 0 2rem',
                  '0 0 3rem',
                  '0 0 4rem',
                  '0 0 7rem',
                ]}
              >
                Careers
              </Text>
              <Divider
                orientation={'horizontal'}
                borderColor={'#E76D0C'}
                borderWidth={3}
                borderRadius={5}
                w={'100%'}
                ml={4}
              />
            </Box>
            <Box
              gap={5}
              display={'flex'}
              borderRadius={10}
              flexDir={'column'}
              p={[5, 5, 10, 10, 10]}
              backgroundColor={'white'}
              boxShadow={'0 50px 200px #000'}
            >
              {pageData.map((dd) => {
                const desData = dd?.description.split('\n');
                const finalDescriptionData = desData.filter(
                  (dd) => dd !== '\r'
                );

                const finalSkillData = removeQuotes(dd?.skill);

                return (
                  <>
                    <Text
                      color={'#E76D0C'}
                      fontFamily={'Poppins'}
                      fontSize={[20, 20, 20, 20, 25]}
                    >
                      {dd?.title}
                    </Text>

                    <Text fontFamily={'Poppins-Bold'} fontSize={15}>
                      Job Description :
                    </Text>

                    {dd?.description && (
                      <>
                        <Text fontSize={12} fontFamily={'Poppins'}>
                          {finalDescriptionData.map((dd, idx) => (
                            <span
                              style={{ display: 'block', marginBottom: '5px' }}
                              key={idx}
                            >
                              {dd}
                            </span>
                          ))}
                        </Text>
                      </>
                    )}

                    <Text fontFamily={'Poppins-Bold'} fontSize={15}>
                      Skill Requirements :
                    </Text>

                    {dd?.skill && (
                      <div className='universal-description'>
                        {parse(finalSkillData)}
                      </div>
                    )}

                    <Divider
                      margin={'1.5rem 0'}
                      borderBottom={'solid 2px #E76D0C'}
                    />
                  </>
                );
              })}

              <Text>
                Interested? contact us at{' '}
                <a
                  style={{ color: '#E76D0C' }}
                  href='mailto:ebi-careers@enterprisebank.com.ph'
                >
                  ebi-careers@enterprisebank.com.ph
                </a>
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Careers;
