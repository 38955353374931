import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FcComboChart } from "react-icons/fc";
import { AiOutlineShop } from "react-icons/ai";
import { useQuery } from "react-query";
import { getEnterpriseLoans } from "../actions/actions";
import { Circles } from "react-loader-spinner";

const Enterprise = () => {
  const toast = useToast();
  const [packages, setPackages] = useState([]);
  const { data, isLoading, error, isError } = useQuery(
    "packages",
    getEnterpriseLoans
  );
  const handleEnterprise = () => {
    if (data) {
      setPackages(data?.data?.packages);
    }
    if (data === null) {
      toast({
        title: "No data!",
        description: "No Data Received",
        status: "warning",
        duration: 3000,
        position: "top-right",
      });
    }
    if (isError) {
      toast({
        title: "Error",
        description: error,
        status: "Error",
        duration: 3000,
        position: "top-right",
      });
    }
  };
  console.log(packages);
  useEffect(() => {
    setTimeout(() => {
      handleEnterprise();
    }, 2000);
  });

  return (
    <>
      <Box
        w={"100%"}
        h={"768px"}
        display={"flex"}
        backgroundColor={"#e6e6e6"}
        flexDir={"column"}
        boxShadow={"3px 3px 200px #000 inset"}
        justifyContent={"center"}
        alignItems={"center"}
        alignContent={"center"}
      >
        <Box
          display={"flex"}
          flexDir={"row"}
          justifyContent={"center"}
          w={"100%"}
          mt={10}
        >
          <Box
            display={"flex"}
            flexDir={"column"}
            justifyContent={"center"}
            w={"75%"}
          >
            <Box
              display={"flex"}
              flexDir={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
              w={"100%"}
            >
              <Text fontFamily={"Poppins"} fontSize={20}>
                Business
              </Text>
              &nbsp;
              <Text fontFamily={"Poppins"} color={"#E76D0C"} fontSize={20}>
                Growth
              </Text>
              <Divider
                orientation={"horizontal"}
                borderColor={"#E76D0C"}
                borderWidth={3}
                borderRadius={5}
                w={"100%"}
                ml={4}
              />
            </Box>
            <Box display={"flex"} flexDir={"row"}>
              <Box
                display={"flex"}
                flexDir={"column"}
                w={"50%"}
                py={10}
                pr={10}
                gap={5}
              >
                <Text fontSize={"2xl"} fontFamily={"Poppins-Bold"}>
                  ENTERPRISE LOANS
                </Text>
                <Text
                  fontSize={[10, 10, 10, 10, 14, 14]}
                  fontFamily={"Poppins"}
                >
                  This loan portfolio is managed by the bank’s Enterprise
                  Lending Department.
                </Text>
                <Text
                  fontSize={[8, 8, 8, 8, 10, 10]}
                  color={"gray.500"}
                  fontFamily={"Poppins"}
                >
                  It is designed to cater “Micro, Small and Medium Enterprises”
                  for capital, purchase of fixed assets and project financing.
                </Text>
              </Box>
              <Box
                display={"flex"}
                flexDir={"column"}
                w={"50%"}
                justifyContent={"center"}
                alignContent={"center"}
                alignItems={"center"}
              >
                <Box backgroundColor={"white"} py={2} px={5} borderRadius={10}>
                  <FcComboChart size={200} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDir={"row"}
          justifyContent={"center"}
          w={"100%"}
        >
          <Box
            display={"flex"}
            flexDir={"column"}
            justifyContent={"center"}
            w={"75%"}
          >
            <Box
              display={"flex"}
              flexDir={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
              w={"100%"}
            >
              <Text fontFamily={"Poppins"} fontSize={20}>
                New
              </Text>
              &nbsp;
              <Text fontFamily={"Poppins"} color={"#E76D0C"} fontSize={20}>
                Packages
              </Text>
              <Divider
                orientation={"horizontal"}
                borderColor={"#E76D0C"}
                borderWidth={3}
                borderRadius={5}
                w={"100%"}
                ml={4}
              />
            </Box>
            <Box
              display={"flex"}
              flexDir={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
              gap={5}
            >
              {isLoading ? (
                <Circles
                  height="80"
                  width="80"
                  color="#E76D0C"
                  ariaLabel="circles-loading"
                  visible={true}
                />
              ) : (
                <>
                  {packages?.map((item) => {
                    return (
                      <Card p={0} w={"220px"} mt={5}>
                        <CardHeader
                          p={2}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={2}
                          m={0}
                        >
                          <AiOutlineShop size={25} color={"#E76D0C"} />
                          <Text fontFamily={"Poppins"} fontSize={12}>
                            {item.description}
                          </Text>
                        </CardHeader>
                        <CardBody
                          display={"flex"}
                          flexDir={"column"}
                          textAlign={"center"}
                          p={0}
                          py={2}
                          gap={3}
                        >
                          <Text fontFamily={"Poppins"} fontSize={12}>
                            {item.price_range}
                          </Text>
                          <Box
                            display={"flex"}
                            flexDir={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Text fontFamily={"Poppins"} fontSize={10}>
                              {item.term[0]}
                            </Text>
                            <Divider borderColor={"black"} w={"65%"} />
                            <Text fontFamily={"Poppins"} fontSize={10}>
                              {item.term[1]}
                            </Text>
                            <Divider borderColor={"black"} w={"65%"} />
                            {item.term[2] ? (
                              <>
                                <Text fontFamily={"Poppins"} fontSize={10}>
                                  {item?.term[2]}
                                </Text>
                              </>
                            ) : null}
                          </Box>
                        </CardBody>
                        <CardFooter display={"flex"} flexDir={"column"}>
                          <Text
                            fontFamily={"Poppins"}
                            fontSize={8}
                            flexWrap={"wrap"}
                            color={"gray"}
                          >
                            {item.title}
                          </Text>
                        </CardFooter>
                      </Card>
                    );
                  })}
                </>
              )}

              {/* <Card p={0} w={"220px"} mt={5}>
                <CardHeader
                  p={2}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={2}
                  m={0}
                >
                  <AiOutlineShop size={25} color={"#E76D0C"} />
                  <Text fontFamily={"Poppins"} fontSize={12}>
                    Enterprise Business Loan
                  </Text>
                </CardHeader>
                <CardBody
                  display={"flex"}
                  flexDir={"column"}
                  textAlign={"center"}
                  p={0}
                  py={2}
                  gap={3}
                >
                  <Text fontFamily={"Poppins"} fontSize={12}>
                    Above P 1,000,000 - P 5,000,000
                  </Text>
                  <Box
                    display={"flex"}
                    flexDir={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Text fontFamily={"Poppins"} fontSize={10}>
                      Credit Line / Term Loan
                    </Text>
                    <Divider borderColor={"black"} w={"65%"} />
                    <Text fontFamily={"Poppins"} fontSize={10}>
                      2-36 months
                    </Text>
                  </Box>
                </CardBody>
                <CardFooter display={"flex"} flexDir={"column"}>
                  <Text
                    fontFamily={"Poppins"}
                    fontSize={8}
                    flexWrap={"wrap"}
                    color={"gray"}
                  >
                    Secured loan for additional working capital for business
                    expansion or purchases related to business operation such as
                    supplies, inventories, equipment and other fixed assets.
                  </Text>
                </CardFooter>
              </Card>
              <Card p={0} w={"220px"} mt={5}>
                <CardHeader
                  p={2}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  m={0}
                  backgroundColor={"#E76D0C"}
                  color={"white"}
                  borderTopStartRadius={5}
                  borderTopEndRadius={5}
                >
                  <Text fontFamily={"Poppins"} fontSize={11}>
                    For Existing EBI Clients
                  </Text>
                </CardHeader>
                <CardHeader
                  p={2}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={2}
                  m={0}
                >
                  <GiTwoCoins size={25} color={"#E76D0C"} />
                  <Text fontFamily={"Poppins"} fontSize={11}>
                    Loans againsts ADB on Deposit
                  </Text>
                </CardHeader>
                <CardBody
                  display={"flex"}
                  flexDir={"column"}
                  textAlign={"center"}
                  p={0}
                  py={2}
                  gap={3}
                >
                  <Text fontFamily={"Poppins"} fontSize={12}>
                    P 50,000 - P 1,000,000
                  </Text>
                  <Box
                    display={"flex"}
                    flexDir={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Text fontFamily={"Poppins"} fontSize={10}>
                      Credit Line
                    </Text>
                    <Divider borderColor={"black"} w={"65%"} />
                    <Text fontFamily={"Poppins"} fontSize={10}>
                      6 months or 180 days
                    </Text>
                    <Divider borderColor={"black"} w={"65%"} />
                    <Text fontFamily={"Poppins"} fontSize={10}>
                      Flexible Interest
                    </Text>
                  </Box>
                </CardBody>
                <CardFooter display={"flex"} flexDir={"column"}>
                  <Text
                    fontFamily={"Poppins"}
                    fontSize={8}
                    flexWrap={"wrap"}
                    color={"gray"}
                  >
                    Designed to assist thriving small entrepreneurs who are at
                    the same time existing depositors of the bank.
                  </Text>
                </CardFooter>
              </Card> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Enterprise;
