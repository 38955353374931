import { useQuery } from 'react-query';
import { FaMapMarker } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import { AiFillPhone, AiFillMail } from 'react-icons/ai';
import { Box, Divider, Text, useToast } from '@chakra-ui/react';

import { getWebsiteSettings } from '../../../actions/actions';
import ReactHelmet from '../../../../../components/ReactHelmet';

const ContactUs = () => {
  const { data, isLoading, isError, error } = useQuery(
    'websiteSettings',
    getWebsiteSettings
  );

  const toast = useToast();

  const [pageData, setPageData] = useState(null);

  const handleExecutives = () => {
    if (data) {
      setPageData(data.data.data);
    }
    if (isError) {
      toast({
        title: 'Error',
        status: 'Error',
        duration: 10000,
        description: error,
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleExecutives();
    }, 0);
  });

  return (
    <>
      <ReactHelmet title={'Contact Us | Bank Enterprise'} />

      <Box
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        alignContent={'center'}
        justifyContent={'flex-start'}
        boxShadow={'3px 3px 200px #000 inset'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
        background={
          pageData?.background_images?.other_page_bg_type === 'color'
            ? pageData?.background_images?.other_page_bg_value
            : `url('${pageData?.background_images?.other_page_bg_value}')`
        }
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
      >
        <Box
          flexDir={'row'}
          display={'flex'}
          gap={[4, 4, 5, 5, 5]}
          px={[5, 5, 5, 10, 10]}
          mt={[0, 0, 10, 10, 10]}
          justifyContent={'flex-end'}
        >
          <Box
            w={'100%'}
            display={'flex'}
            flexDir={'column'}
            gap={[5, 5, 5, 10, 10]}
            px={[0, 0, 0, 0, 0, 10]}
            mb={[0, 0, 0, 0, 0, 20]}
          >
            <Box
              w={'100%'}
              flexDir={'row'}
              display={'flex'}
              alignItems={'center'}
              alignContent={'center'}
              justifyContent={'center'}
              marginTop={[10, 10, 0, 0, 0]}
            >
              <Text
                width={'100%'}
                color={'black'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
                flex={[
                  '0 0 7rem',
                  '0 0 7rem',
                  '0 0 7rem',
                  '0 0 8rem',
                  '0 0 9rem',
                ]}
              >
                Contact Us
              </Text>
              <Divider
                ml={4}
                w={'80%'}
                borderWidth={3}
                borderRadius={5}
                borderColor={'#E76D0C'}
                orientation={'horizontal'}
              />
            </Box>
            <Box
              gap={5}
              display={'flex'}
              borderRadius={10}
              flexDir={'column'}
              p={[5, 5, 5, 10, 10]}
              backgroundColor={'white'}
              boxShadow={'0 50px 200px #000'}
            >
              {pageData && pageData?.company_contact && (
                <>
                  <Box display={'flex'} fontFamily={'Poppins'} gap={3}>
                    <FaMapMarker color={'#E76D0C'} size={25} />
                    <Text fontSize={[12, 12, 14, 16, 16]}>
                      {pageData?.company_contact?.address}
                    </Text>
                  </Box>
                  <Box display={'flex'} fontFamily={'Poppins'} gap={3}>
                    <AiFillMail color={'#E76D0C'} size={25} />
                    <Text fontSize={[12, 12, 14, 16, 16]}>
                      {pageData?.company_contact?.email}
                    </Text>
                  </Box>
                  <Box display={'flex'} fontFamily={'Poppins'} gap={3}>
                    <AiFillPhone color={'#E76D0C'} size={25} />
                    <Text fontSize={[12, 12, 14, 16, 16]}>
                      {pageData?.company_contact?.mobile}
                    </Text>
                  </Box>
                  <Text fontSize={[12, 12, 14, 16, 16]} fontFamily={'Poppins'}>
                    We are open from Monday to Friday from 8:00 AM – 5:00 PM
                  </Text>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ContactUs;
