import axios from "axios";

const base_url = `https://bankenterprise-admin.highlysucceed.com`;

export const getEnterpriseLoans = () => {
  try {
    const response = axios.get(
      `${base_url}/api/cms?filter=page.personal_banking.enterprise_loans`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getDeposits = () => {
  try {
    const response = axios.get(
      `${base_url}/api/cms?filter=page.personal_banking.deposits`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getConsumerLoans = () => {
  try {
    const response = axios.get(
      `${base_url}/api/cms?filter=page.personal_banking.consumer_loans`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getOtherLoans = () => {
  try {
    const response = axios.get(
      `${base_url}/api/cms?filter=page.personal_banking.other_loans`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
