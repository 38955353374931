import axios from 'axios';
import { FiMapPin } from 'react-icons/fi';
import { BiInfoCircle } from 'react-icons/bi';
import React, { useState, useEffect } from 'react';
import { AiFillBank, AiOutlineClose } from 'react-icons/ai';
import { Box, Image, Text, Button } from '@chakra-ui/react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { BsBriefcase, BsNewspaper, BsBank } from 'react-icons/bs';
import { FaUserAlt, FaBriefcase, FaRegUser } from 'react-icons/fa';

import pdic from '../assets/PDIC.png';
import about from '../assets/about_us.jpg';
import bancnet from '../assets/bancnet.png';
import newLogo from '../assets/updatedBELogo.jpg';
import personal from '../assets/personal_banking.jpg';
import business from '../assets/business_banking.jpeg';
import { ReactComponent as CloseSVG } from '../assets/close.svg';
import { ReactComponent as HamburgerSVG } from '../assets/hamburger.svg';

const aboutNavs = [
  { title: 'About Us', icon: <FaRegUser size={20} />, link: '/About' },
  // { title: "Products", icon: <BsBoxSeam size={20} />, link: "/" },
  // { title: "Services", icon: <FaRegHandshake size={20} />, link: "/" },
  { title: 'Careers', icon: <BsBriefcase size={20} />, link: '/About/careers' },
  // { title: "Directory", icon: <SlNotebook size={20} />, link: "/" },
  {
    title: 'Acquired Properties',
    icon: <BsBank size={20} />,
    link: '/About/properties',
  },
  {
    title: 'News and Advisories',
    icon: <BsNewspaper size={20} />,
    link: '/About/news',
  },
  {
    title: 'Branches and ATMs',
    icon: <FiMapPin size={20} />,
    link: '/About/atmbranch',
  },
  {
    title: 'Corporate Information',
    icon: <BiInfoCircle size={20} />,
    link: '/About/info',
  },
  // { title: "Privacy Statement", icon: <BiLockAlt size={20} />, link: "/" },
  // { title: "Terms Of Use", icon: <RiFileTextLine size={20} />, link: "/" },
  // { title: "Contact Us", icon: <AiOutlinePhone size={20} />, link: "/" },
];

const Sidebar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const navigate = useNavigate();

  const [pageData, setPageData] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getWebsiteData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/website-settings`
      );

      setPageData(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRedirect = (link) => {
    navigate(link);
  };

  useEffect(() => {
    setTimeout(() => {
      getWebsiteData();
    }, 0);
  }, []);

  useEffect(() => {
    console.log('pageData', pageData);
  }, [pageData]);

  console.log('currentPath', currentPath);

  return (
    <>
      <Box
        w={'2rem'}
        h={'2rem'}
        zIndex={99}
        top={'10px'}
        left={'10px'}
        padding={'4px'}
        borderRadius={'5px'}
        position={'absolute'}
        onClick={() => setIsMenuOpen(true)}
        transition={'opacity 0.3s, visibility 0.3s'}
        backgroundColor={'rgba(255, 255,255, 0.3);'}
        display={['flex', 'flex', 'none', 'none', 'none']}
        opacity={[isMenuOpen ? 0 : 1, isMenuOpen ? 0 : 1, 1, 1, 1]}
        visibility={[
          isMenuOpen ? 'collapse' : 'visible',
          isMenuOpen ? 'collapse' : 'visible',
          'visible',
          'visible',
          'visible',
        ]}
      >
        <HamburgerSVG />
      </Box>

      <Box
        py={2}
        w={'100%'}
        zIndex={2}
        h={'100%'}
        display={'flex'}
        overflowY={'unset'}
        minHeight={'100vh'}
        borderColor={'#000'}
        flexDirection={'column'}
        backgroundColor={'white'}
        padding={'1rem 1rem 2rem'}
        transition={'opacity 0.3s, visibility 0.3s'}
        maxWidth={['200px', '200px', '200px', '304px', '304px']}
        opacity={[isMenuOpen ? 1 : 0, isMenuOpen ? 1 : 0, 1, 1, 1]}
        position={['absolute', 'absolute', 'relative', 'relative', 'relative']}
        visibility={[
          isMenuOpen ? 'visible' : 'collapse',
          isMenuOpen ? 'visible' : 'collapse',
          'visible',
          'visible',
          'visible',
        ]}
      >
        <Box
          gap={5}
          padding={5}
          flexDir={'row'}
          display={'flex'}
          position={'relative'}
          justifyContent={'flex-start'}
        >
          <Box
            top={0}
            left={0}
            h={'1rem'}
            w={'1rem'}
            position={'absolute'}
            onClick={() => setIsMenuOpen(false)}
            display={['block', 'block', 'none', 'none', 'none']}
          >
            <CloseSVG w={'100%'} h={'100%'} />
          </Box>

          <Link to={'/'}>
            <Image src={newLogo} objectFit={'contain'} w={'100%'} />
          </Link>
        </Box>
        <Box
          gap={5}
          flexDir={'column'}
          alignItems={'center'}
          marginX={[0, 0, 0, 5, 5]}
          justifyContent={'flex-start'}
          display={isClicked ? 'none' : 'false'}
        >
          <Box
            my={5}
            zIndex={2}
            borderRadius={10}
            color={'#E76D0C'}
            cursor={'pointer'}
            // className='nav-link'
            position={'relative'}
            transform={'scale(1)'}
            boxShadow={'0px 10px 10px #888888'}
            transition={'transform 0.5s ease-in-out, background-color 0.5s ease-in-out;'}
            _hover={{
              color: 'white',
              transform: 'scale(1.05)',
              backgroundColor: '#E76D0C',
            }}
          >
            <Image
              width={'100%'}
              objectFit={'cover'}
              borderTopRadius={10}
              height={['70px', '70px', '70px', '123px', '123px']}
              src={pageData?.background_images?.personal_banking || personal}
              onClick={() => handleRedirect('/personal-banking')}
            />
            <Box
              gap={2}
              display={'flex'}
              alignItems={'center'}
              paddingY={[2, 2, 3, 3, 3]}
              paddingX={[4, 4, 5, 5, 5]}
              justifyContent={'space-between'}
            >
              <Box gap={2} display={'flex'} alignItems={'center'}>
                <FaUserAlt />
                <Text
                  fontFamily={'Poppins'}
                  fontSize={[10, 10, 10, 10, 12, 16]}
                >
                  Personal Banking
                </Text>
              </Box>
            </Box>
          </Box>

          <Box
            my={5}
            zIndex={2}
            borderRadius={10}
            color={'#E76D0C'}
            cursor={'pointer'}
            position={'relative'}
            transform={'scale(1)'}
            transitionDuration={'.5s'}
            boxShadow={'0px 10px 10px #888888'}
            _hover={{
              color: 'white',
              transform: 'scale(1.05)',
              transitionDuration: '.5s',
              backgroundColor: '#E76D0C',
            }}
          >
            <Image
              width={'100%'}
              objectFit={'cover'}
              borderTopRadius={10}
              height={['70px', '70px', '70px', '123px', '123px']}
              onClick={() => handleRedirect('/business-banking')}
              src={pageData?.background_images?.business_banking || business}
            />
            <Box
              gap={2}
              display={'flex'}
              alignItems={'center'}
              paddingY={[2, 2, 3, 3, 3]}
              paddingX={[4, 4, 5, 5, 5]}
              justifyContent={'space-between'}
            >
              <Box gap={2} display={'flex'} alignItems={'center'}>
                <FaBriefcase />
                <Text
                  fontFamily={'Poppins'}
                  fontSize={[10, 10, 10, 10, 12, 16]}
                >
                  Business Banking
                </Text>
              </Box>
            </Box>
          </Box>

          <Box
            my={5}
            zIndex={2}
            borderRadius={10}
            color={'#E76D0C'}
            cursor={'pointer'}
            position={'relative'}
            transform={'scale(1)'}
            transitionDuration={'.5s'}
            boxShadow={'0px 10px 10px #888888'}
            _hover={{
              color: 'white',
              transform: 'scale(1.05)',
              transitionDuration: '.5s',
              backgroundColor: '#E76D0C',
            }}
            onClick={() => {
              handleRedirect('/About');
              setIsClicked(true);
            }}
          >
            <Image
              width={'100%'}
              objectFit={'cover'}
              borderTopRadius={10}
              height={['70px', '70px', '70px', '123px', '123px']}
              src={pageData?.background_images?.about_us || about}
            />
            <Box
              gap={2}
              display={'flex'}
              alignItems={'center'}
              paddingY={[2, 2, 3, 3, 3]}
              paddingX={[4, 4, 5, 5, 5]}
              justifyContent={'space-between'}
            >
              <Box gap={2} display={'flex'} alignItems={'center'}>
                <AiFillBank />
                <Text
                  fontFamily={'Poppins'}
                  fontSize={[10, 10, 10, 10, 12, 16]}
                >
                  About Us
                </Text>
              </Box>
            </Box>
          </Box>

          <Box display={'flex'} gap={3} w={'100%'}>
            <Text
              w={'100%'}
              fontSize={[10, 10, 10, 12, 12]}
              lineHeight={['1.2', '1.2', '1.5', '1.5', '1.5']}
            >
              Enterprise Bank, Inc. (A Rural Bank) is regulated by the Bangko
              Sentral ng Pilipinas. For inquiries and comments, contact us at{' '}
              {pageData?.company_contact?.email} or call us at{' '}
              {pageData?.company_contact?.telephone}
              <Text
                gap={'1rem'}
                display={'flex'}
                marginTop={'1rem'}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={['column', 'column', 'column', 'row', 'row']}
              >
                <Image
                  src={bancnet}
                  width={'auto'}
                  height={['1.5rem', '1.5rem', '1.5rem', '2rem', '2rem']}
                />
                <Image
                  src={pdic}
                  width={'auto'}
                  height={['1.5rem', '1.5rem', '1.5rem', '2rem', '2rem']}
                />
              </Text>
            </Text>
          </Box>
        </Box>
        {!isClicked ? null : (
          <Box>
            <Box
              my={2}
              px={2}
              width={'100%'}
              display={'flex'}
              justifyContent={'end'}
            >
              <AiOutlineClose
                color={'#E76D0C'}
                size={24}
                onClick={() => {
                  setIsClicked(false);
                }}
                cursor={'pointer'}
              />
            </Box>

            {aboutNavs.map((item) => {
              return (
                <>
                  <Link to={item.link}>
                    <Button
                      mb={2}
                      width={'100%'}
                      display={'flex'}
                      justifyContent={'start'}
                      color={currentPath === item?.link ? '#FFF' : '#E76D0C'}
                      onClick={() => {
                        setIsClicked(true);
                      }}
                      backgroundColor={
                        currentPath === item?.link ? '#E76D0C' : '#FFF'
                      }
                      _hover={{
                        color: '#FFF',
                        transitionDuration: '.5s',
                        backgroundColor: '#E76D0C',
                      }}
                    >
                      <Box
                        gap={2}
                        paddingY={3}
                        paddingX={5}
                        display={'flex'}
                        alignItems={'center'}
                      >
                        {item.icon}
                        <Text
                          fontFamily={'Poppins'}
                          fontSize={[10, 10, 10, 10, 12, 16]}
                        >
                          {item.title}
                        </Text>
                      </Box>
                    </Button>
                  </Link>
                </>
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
};

export default Sidebar;
