import React, { useState, useEffect } from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';

const CustomMap = (props) => {
  const { google, locations } = props;

  const [mapData, setMapData] = useState({});

  const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  useEffect(() => {
    if (isObjectEmpty(locations) === false) {
      setMapData({
        ...locations,
        lat: parseFloat(locations?.lat),
        lng: parseFloat(locations?.lng),
      });
    }
  }, [locations]);

  return (
    <>
      {mapData && (
        <Map
          zoom={18}
          google={google}
          disableDefaultUI={true}
          center={{ lat: mapData?.lat, lng: mapData?.lng }}
          initialCenter={{ lng: 126.308956, lat: 8.886076 }}
          containerStyle={{ display: 'flex', position: 'relative' }}
          style={{
            zIndex: 10,
            width: '100%',
            height: 400,
            display: 'flex',
            position: 'relative',
          }}
        >
          <Marker
            key={mapData?.id}
            name={mapData?.title}
            title={mapData?.address}
            position={{ lat: mapData?.lat, lng: mapData?.lng }}
          />
        </Map>
      )}
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBmyWZHpAEeVLRujFvNBjA09GF6SrJpChs',
})(CustomMap);
