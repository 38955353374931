import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { AiOutlineUser } from "react-icons/ai";
import { useQuery } from "react-query";
import { getConsumerLoans } from "../actions/actions";
import { Circles } from "react-loader-spinner";

const Consumer = () => {
  const { data, isLoading, isError, error } = useQuery(
    "packages",
    getConsumerLoans
  );
  const toast = useToast();
  const [consumer, setConsumer] = useState();

  const handleConsumer = () => {
    if (data) {
      setConsumer(data?.data?.packages);
    }
    if (!data) {
      toast({
        title: "No data!",
        description: "No Data Received",
        status: "warning",
        duration: 3000,
        position: "top-right",
      });
    }
    if (isError) {
      toast({
        title: "Error",
        description: error,
        status: "Error",
        duration: 10000,
        position: "top-right",
      });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      handleConsumer();
    }, 10000);
  });

  return (
    <>
      <Box
        w={"100%"}
        h={"768px"}
        display={"flex"}
        backgroundColor={"#e6e6e6"}
        flexDir={"column"}
        boxShadow={"3px 3px 200px #000 inset"}
        justifyContent={"center"}
        alignItems={"center"}
        alignContent={"center"}
      >
        <Box
          display={"flex"}
          flexDir={"row"}
          justifyContent={"center"}
          w={"100%"}
          mt={10}
        >
          <Box
            display={"flex"}
            flexDir={"column"}
            justifyContent={"center"}
            w={"75%"}
          >
            <Box
              display={"flex"}
              flexDir={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
              w={"100%"}
            >
              <Text fontFamily={"Poppins"} color={"#E76D0C"} fontSize={20}>
                Packages
              </Text>
              <Divider
                orientation={"horizontal"}
                borderColor={"#E76D0C"}
                borderWidth={3}
                borderRadius={5}
                w={"100%"}
                ml={4}
              />
            </Box>
            <Box display={"flex"} flexDir={"row"}>
              <Box
                display={"flex"}
                flexDir={"column"}
                w={"50%"}
                py={10}
                pr={10}
                gap={5}
              >
                <Text fontSize={"2xl"} fontFamily={"Poppins-Bold"}>
                  CONSUMER LOANS
                </Text>
                <Text
                  fontSize={[10, 10, 10, 10, 14, 14]}
                  fontFamily={"Poppins"}
                >
                  For new clients
                </Text>
              </Box>
              <Box
                display={"flex"}
                flexDir={"column"}
                w={"50%"}
                justifyContent={"center"}
                alignContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  {isLoading ? (
                    <Circles
                      height="80"
                      width="80"
                      color="#E76D0C"
                      ariaLabel="circles-loading"
                      visible={true}
                    />
                  ) : (
                    <>
                      <Card w={"100%"} mt={5}>
                        <CardHeader
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={2}
                          m={0}
                        >
                          <AiOutlineUser size={25} color={"#E76D0C"} />
                          <Text fontFamily={"Poppins"} fontSize={16}>
                            {consumer[0]?.title}
                          </Text>
                        </CardHeader>
                        <CardBody
                          display={"flex"}
                          flexDir={"column"}
                          textAlign={"center"}
                          gap={3}
                        >
                          <Text fontFamily={"Poppins-bold"} fontSize={14}>
                            Up to P {consumer[0]?.price_range}
                          </Text>
                          <Box
                            display={"flex"}
                            flexDir={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Text fontFamily={"Poppins"} fontSize={12}>
                              {consumer[0]?.term}
                            </Text>
                            <Divider borderColor={"black"} w={"75%"} />
                            <Text fontFamily={"Poppins"} fontSize={12}>
                              {consumer[0]?.term_duration}
                            </Text>
                          </Box>
                        </CardBody>
                      </Card>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Consumer;
