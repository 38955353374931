import axios from 'axios';
import { useQuery } from 'react-query';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Image,
  Select,
  Button,
  Divider,
  useToast,
} from '@chakra-ui/react';

import { getReports } from '../actions/actions';
import TopLinks from '../../../components/TopLinks';
import ReactHelmet from '../../../components/ReactHelmet';

import reports from '../../../assets/reports.jpg';

const PageFour = () => {
  const { data, isLoading, isError, error } = useQuery(
    'getReports',
    getReports
  );

  const toast = useToast();

  const [pageData, setPageData] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState();
  const [selectedData, setSelectedData] = useState('');

  const handleReports = () => {
    if (data) {
      setPageData(data.data.data);
    }
    if (isError) {
      toast({
        title: 'Error',
        status: 'Error',
        duration: 10000,
        description: error,
        position: 'top-right',
      });
    }
  };

  const handleChangeYear = (e) => {
    const { value } = e.target;

    setSelectedData(value);
  };

  const handleViewReport = () => {
    if (selectedData === '') return;

    const reportData = pageData.find((dd) => +dd?.id === +selectedData);

    var link = document.createElement('a');
    link.href = reportData?.file_path;
    link.target = '_blank';
    link.click();
  };

  useEffect(() => {
    setTimeout(() => {
      handleReports();
    }, 0);
  });

  useEffect(() => {
    const getWebsiteData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/website-settings`
        );

        setBackgroundImage(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getWebsiteData();
  }, []);

  useEffect(() => {
    console.log('backgroundImage', backgroundImage);
  }, [backgroundImage]);

  return (
    <>
      <ReactHelmet title={'Annual Reports | Bank Enterprise'} />

      <Box
        gap={5}
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        justifyContent={'start'}
        boxShadow={'3px 3px 200px #000 inset'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
        background={
          backgroundImage?.background_images?.other_page_bg_type === 'color'
            ? backgroundImage?.background_images?.other_page_bg_value
            : `url('${backgroundImage?.background_images?.other_page_bg_value}')`
        }
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
      >
        <Box
          flexDir={'row'}
          display={'flex'}
          gap={[4, 4, 5, 5, 5]}
          px={[5, 5, 5, 10, 10]}
          mt={[0, 0, 10, 10, 10]}
          justifyContent={'flex-end'}
        >
          <TopLinks />
        </Box>

        <Box
          w={'100%'}
          flexDir={'row'}
          display={'flex'}
          justifyContent={'center'}
        >
          <Box display={'flex'} flexDir={'column'} w={'100%'} px={10} mb={20}>
            <Box
              w={'100%'}
              flexDir={'row'}
              display={'flex'}
              textAlign={'start'}
              alignItems={'center'}
              alignContent={'flex-start'}
              justifyContent={'flex-start'}
            >
              <Text
                width={'100%'}
                color={'white'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
                flex={[
                  '0 0 9rem',
                  '0 0 9rem',
                  '0 0 10rem',
                  '0 0 11rem',
                  '0 0 13rem',
                ]}
              >
                Annual <span style={{ color: '#E76D0C' }}>Reports</span>
              </Text>

              <Divider
                ml={6}
                w={'83%'}
                borderWidth={3}
                borderRadius={5}
                borderColor={'#E76D0C'}
                orientation={'horizontal'}
              />
            </Box>
            <Box
              mt={10}
              display={'flex'}
              borderRadius={10}
              backgroundColor={'white'}
              justifyContent={'space-between'}
              h={['auto', 'auto', 60, 60, 60]}
              boxShadow={'10px 20px 40px #9c9c9c'}
              flexDirection={[
                'column-reverse',
                'column-reverse',
                'row',
                'row',
                'row',
              ]}
            >
              <Box
                mt={10}
                px={10}
                display={'flex'}
                flexDir={'column'}
                w={['100%', '100%', '60%', '60%', '60%']}
              >
                <Text
                  my={3}
                  color={'#E76D0C'}
                  fontFamily={'Poppins'}
                  fontSize={[18, 18, 25, 30, 32]}
                >
                  Select Report
                </Text>
                <Select
                  size={'lg'}
                  value={selectedData}
                  fontFamily={'Poppins'}
                  onChange={handleChangeYear}
                  fontSize={[16, 16, 16, 18, 18]}
                >
                  <option value='' hidden>
                    - Select Year -
                  </option>
                  {pageData &&
                    pageData.length > 0 &&
                    pageData.map((dd) => (
                      <option key={dd?.id} value={dd?.id}>
                        {dd?.name}
                      </option>
                    ))}
                </Select>
                <Button
                  my={5}
                  color={'white'}
                  borderRadius={10}
                  onClick={handleViewReport}
                  backgroundColor={'#E76D0C'}
                  fontSize={[14, 14, 14, 15, 16]}
                  w={['7rem', '7rem', '7rem', '8rem', '10rem']}
                >
                  View Report
                </Button>
              </Box>

              <Image
                borderRadius={10}
                objectFit={'cover'}
                w={['100%', '10 0%', '30%', '30%', '30%']}
                height={['10rem', '10rem', 'auto', 'auto', 'auto']}
                src={backgroundImage?.background_images?.annual_report}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PageFour;
