import { useQuery } from 'react-query';
import React, { useState, useEffect } from 'react';
import { Box, Image, Text, useToast } from '@chakra-ui/react';

import { getWebsiteSettings } from '../screens/About/actions/actions';

import map from '../assets/map.png';
import messenger from '../assets/messenger.png';

const TopLinks = () => {
  const { data, isLoading, isError, error } = useQuery(
    'websiteSettings',
    getWebsiteSettings
  );

  const toast = useToast();

  const [pageData, setPageData] = useState(null);

  const handleWebsiteSettings = () => {
    if (data) {
      setPageData(data.data.data);
    }
    if (isError) {
      toast({
        title: 'Error',
        status: 'Error',
        duration: 10000,
        description: error,
        position: 'top-right',
      });
    }
  };

  const openLinkInNewTab = (url) => {
    var link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
  };

  useEffect(() => {
    setTimeout(() => {
      handleWebsiteSettings();
    }, 0);
  });

  return (
    <>
      <Image
        src={messenger}
        style={{ cursor: 'pointer' }}
        w={['2rem', '2rem', '2rem', '3rem', '3rem']}
        h={['2rem', '2rem', '2rem', '3rem', '3rem']}
        onClick={() => openLinkInNewTab(pageData?.home_page_details?.messenger)}
      />

      <Image
        src={map}
        w={['2rem', '2rem', '2rem', '3rem', '3rem']}
        h={['2rem', '2rem', '2rem', '3rem', '3rem']}
        style={{ cursor: 'pointer' }}
        onClick={() =>
          openLinkInNewTab(pageData?.home_page_details?.google_map_link)
        }
      />
    </>
  );
};

export default TopLinks;
