import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaMapPin } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Grid, GridItem, Text } from '@chakra-ui/react';

import ReactHelmet from '../../../../components/ReactHelmet';

const data = [
  {
    title: 'ATM Locator',
    link: '/About/atm',
  },
  {
    title: 'Branch Locator',
    link: '/About/branch',
  },
];

const MainNav = () => {
  const [websiteSettings, setWebsiteSettings] = useState([]);

  useEffect(() => {
    const getWebsiteData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/website-settings`
        );

        setWebsiteSettings(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getWebsiteData();
  }, []);

  return (
    <>
      <ReactHelmet title={'ATM and Branch Locator | Bank Enterprise'} />

      <Box
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        justifyContent={'center'}
        boxShadow={'3px 3px 200px #000 inset'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
        background={
          websiteSettings?.background_images?.other_page_bg_type === 'color'
            ? websiteSettings?.background_images?.other_page_bg_value
            : `url('${websiteSettings?.background_images?.other_page_bg_value}')`
        }
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
      >
        <Box
          w={'100%'}
          flexDir={'row'}
          display={'flex'}
          justifyContent={'center'}
        >
          <Box display={'flex'} flexDir={'column'} w={'100%'}>
            <Box
              w={'100%'}
              flexDir={'row'}
              display={'flex'}
              alignItems={'center'}
              alignContent={'center'}
              padding={['2rem', '2rem', 0, 0, 0]}
              justifyContent={'center'}
            >
              <Text
                width={'100%'}
                color={'#E76D0C'}
                fontFamily={'Poppins'}
                fontSize={[14, 18, 18, 20, 25]}
                flex={[
                  '0 0 11rem',
                  '0 0 11rem',
                  '0 0 14rem',
                  '0 0 17rem',
                  '0 0 20rem',
                ]}
              >
                ATM and Branch Locator
              </Text>
              <Divider
                ml={4}
                borderWidth={3}
                borderRadius={5}
                borderColor={'#E76D0C'}
                orientation={'horizontal'}
                w={['10rem', '10rem', '45%', '45%', '45%']}
              />
            </Box>
            <Box display={'flex'} justifyContent={'center'} my={5} w={'100%'}>
              <Grid
                templateColumns={[
                  'repeat(1, 1fr)',
                  'repeat(1, 1fr)',
                  'repeat(2, 1fr)',
                  'repeat(2, 1fr)',
                  'repeat(2, 1fr)',
                ]}
                gap={5}
              >
                {data.map((item) => {
                  return (
                    <GridItem w='100%'>
                      <Link to={item.link}>
                        <Button
                          m={0}
                          p={10}
                          gap={2}
                          w={'100%'}
                          flexDir={'row'}
                          display={'flex'}
                          color={'#E76D0C'}
                          alignItems={'center'}
                          justifyContent={'flex-start'}
                          _hover={{
                            backgroundColor: '#E76D0c',
                            transitionDelay: '1s ease-in',
                            color: 'white',
                          }}
                        >
                          <FaMapPin size={28} />
                          <Text
                            fontFamily={'Poppins-Bold'}
                            fontSize={[18, 18, 22, 22, 25]}
                          >
                            {item.title}
                          </Text>
                        </Button>
                      </Link>
                    </GridItem>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MainNav;
