import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Box, Image, Text, useToast } from '@chakra-ui/react';

import TopLinks from '../../../components/TopLinks';
import ReactHelmet from '../../../components/ReactHelmet';
import { getWebsiteSettings } from '../../About/actions/actions';

import right from '../../../assets/right.png';

const PageOne = () => {
  const { data, isLoading, isError, error } = useQuery(
    'websiteSettings',
    getWebsiteSettings
  );

  const toast = useToast();

  const [pageData, setPageData] = useState(null);

  const handleExecutives = () => {
    if (data) {
      setPageData(data.data.data);
    }
    if (isError) {
      toast({
        title: 'Error',
        status: 'Error',
        duration: 10000,
        description: error,
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleExecutives();
    }, 0);
  });

  useEffect(() => {
    console.log('pageData', pageData);
    console.log(
      'pageData?.home_page_details?.banner',
      pageData?.home_page_details?.banner
    );
  }, [pageData]);

  return (
    <>
      <ReactHelmet title={'Bank Enterprise'} />

      <Box
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        position={'relative'}
        backgroundSize={'cover'}
        backgroundPosition={'center'}
        backgroundRepeat={'no-repeat'}
        justifyContent={'space-between'}
        boxShadow={'3px 3px 200px #000 inset'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        backgroundImage={`url(${pageData?.background_images?.main_page})`}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
      >
        <Box
          flexDir={'row'}
          display={'flex'}
          gap={[4, 4, 5, 5, 5]}
          px={[5, 5, 5, 10, 10]}
          mt={[0, 0, 10, 10, 10]}
          justifyContent={'flex-end'}
        >
          <TopLinks />
        </Box>
        <Box
          my={10}
          px={10}
          display={'flex'}
          letterSpacing={1}
          gap={[0, 2, 3, 3, 3]}
          alignItems={'center'}
          justifyContent={'flex-end'}
          flexDir={['column', 'column', 'row', 'row', 'row']}
        >
          <Text
            color={'#fff'}
            fontFamily={'Poppins'}
            fontSize={[20, 20, 20, 30, 30]}
          >
            BANKING THAT
          </Text>

          <Text
            color={'#E76D0C'}
            fontFamily={'Poppins'}
            fontSize={[20, 20, 20, 30, 30]}
          >
            FITS YOUR NEEDS
          </Text>

          <Link to={'/whatwedo'}>
            <Image
              src={right}
              mt={[10, 10, 0, 0, 0]}
              w={['30px', '30px', '30px', '40px', '40px']}
            />
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default PageOne;
