import axios from 'axios';

const base_url = process.env.REACT_APP_API_BASE_URL;

export const getProperties = () => {
  try {
    const response = axios.get(`${base_url}/api/properties`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getExecutives = () => {
  try {
    const response = axios.get(`${base_url}/api/personnels/executives`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getDirectors = () => {
  try {
    const response = axios.get(
      `${base_url}/api/personnels/board-of-directors`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getWebsiteSettings = () => {
  try {
    const response = axios.get(`${base_url}/api/website-settings`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getPersonalBankings = () => {
  try {
    const response = axios.get(
      `${base_url}/api/product-list/personal-banking`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getBusinessBankings = () => {
  try {
    const response = axios.get(
      `${base_url}/api/product-list/business-banking`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getServices = () => {
  try {
    const response = axios.get(`${base_url}/api/services`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getFeedbacks = () => {
  try {
    const response = axios.get(`${base_url}/api/feedbacks`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getReports = () => {
  try {
    const response = axios.get(`${base_url}/api/annual-reports`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};
