import React from "react";
import { Route, Routes } from "react-router-dom";
import Consumer from "./components/consumer";
import Deposits from "./components/deposits";
import Enterprise from "./components/enterprise";
import Other from "./components/other";

const Business = () => {
  return (
    <Routes>
      <Route path={"/enterprise"} element={<Enterprise />} />
      <Route path={"/consumer"} element={<Consumer />} />
      <Route path={"/deposits"} element={<Deposits />} />
      <Route path={"/other"} element={<Other />} />
    </Routes>
  );
};

export default Business;
