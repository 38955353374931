import axios from 'axios';
import { Box } from '@chakra-ui/react';
import { AiFillBank } from 'react-icons/ai';
import React, { useState, useEffect } from 'react';

import MainView from './components/mainView';
import ViewProduct from './components/viewProduct';
import ReactHelmet from '../../../../components/ReactHelmet';

const PersonalBanking = () => {
  /*
  0 === Main Menu
  1 === View Selected Product
  */
  const [pageData, setPageData] = useState([]);
  const [showScreen, setShowScreen] = useState(0);
  const [selectedData, setSelectedData] = useState(null);
  const [websiteSettings, setWebsiteSettings] = useState([]);

  const convertedArray = (array) => {
    return array.reduce((accumulator, current, index) => {
      if (index % 2 === 0) {
        accumulator.push([current]);
      } else {
        accumulator[accumulator.length - 1].push(current);
      }
      return accumulator;
    }, []);
  };

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/product-list/personal-banking`
        );

        // const convertedArr = convertedArray(response?.data?.data);

        setPageData(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getWebsiteData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/website-settings`
        );

        setWebsiteSettings(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getProducts();
    getWebsiteData();
  }, []);

  useEffect(() => {
    console.log('websiteSettings', websiteSettings);
  }, [websiteSettings]);

  return (
    <>
      <ReactHelmet title={'Personal Banking | Bank Enterprise'} />

      <Box
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        justifyContent={'center'}
        boxShadow={'3px 3px 200px #000 inset'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
        background={
          websiteSettings?.background_images?.other_page_bg_type === 'color'
            ? websiteSettings?.background_images?.other_page_bg_value
            : `url('${websiteSettings?.background_images?.other_page_bg_value}')`
        }
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
      >
        <Box
          w={'100%'}
          flexDir={'row'}
          display={'flex'}
          justifyContent={'center'}
        >
          <Box display={'flex'} flexDir={'column'} w={'100%'}>
            <Box
              opacity={showScreen === 0 ? '1' : '0'}
              display={showScreen === 0 ? 'block' : 'none'}
              transition={'opacity 0.3s, visibility 0.3s;'}
              visibility={showScreen === 0 ? 'visible' : 'hidden'}
            >
              <MainView
                pageData={pageData}
                showScreen={showScreen}
                setShowScreen={setShowScreen}
                setSelectedData={setSelectedData}
              />
            </Box>

            <Box
              transition={'opacity 0.3s, visibility 0.3s;'}
              visibility={showScreen === 0 ? 'hidden' : 'visible'}
              opacity={showScreen === 0 ? '0' : '1'}
            >
              <ViewProduct
                selectedData={selectedData}
                setShowScreen={setShowScreen}
                setSelectedData={setSelectedData}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PersonalBanking;
