import { Box } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './screens/Home';
import About from './screens/About';
import Business from './screens/Business';
import Sidebar from './components/sidebar';

import './App.css';
import './assets/sass/main.scss';

const query = new QueryClient();

function App() {
  return (
    <>
      <BrowserRouter>
        <Box
          flexDir={'row'}
          display={'flex'}
          minHeight={'100vh'}
          overflow={'hidden'}
          overflowY={'hidden'}
          position={'relative'}
          justifyContent={'flex-start'}
        >
          <Sidebar />

          <QueryClientProvider client={query}>
            <Routes>
              <Route index element={<Home />} />
              <Route path={'/*'} element={<Home />} />
              <Route path={'/Business/*'} element={<Business />} />
              <Route path={'/About/*'} element={<About />} />
            </Routes>
          </QueryClientProvider>
        </Box>
      </BrowserRouter>
    </>
  );
}

export default App;
