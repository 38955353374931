import axios from 'axios';
import { useQuery } from 'react-query';
import { FaMapMarker } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Input,
  Button,
  Divider,
  useToast,
  Textarea,
  FormLabel,
  FormControl,
  FormHelperText,
  FormErrorMessage,
} from '@chakra-ui/react';

import ReactHelmet from '../../../../../components/ReactHelmet';

import '../../styles.scss';

const base_url = process.env.REACT_APP_API_BASE_URL;

const Complaint = () => {
  const toast = useToast();

  const [message, setMessage] = useState('');
  const [websiteSettings, setWebsiteSettings] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    title: '',
    email: '',
    content: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const hasEmptyValues = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === '') {
        return true;
      }
    }
    return false;
  };

  const handleSend = async () => {
    setMessage('');

    if (hasEmptyValues(formData)) {
      setMessage('Please fill in the empty fields.');
      return;
    }

    try {
      const response = await axios.post(`${base_url}/api/contact-us`, formData);

      if (response) {
        setMessage('Message successfully sent.');

        setFormData({
          name: '',
          title: '',
          email: '',
          content: '',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      const { data } = error?.response;

      if (data?.errors && data?.errors?.email) {
        setMessage(data?.errors?.email[0]);
        return;
      }

      setMessage('Something went wrong. please refresh the page');
    }
  };

  useEffect(() => {
    const getWebsiteData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/website-settings`
        );

        setWebsiteSettings(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getWebsiteData();
  }, []);

  return (
    <>
      <ReactHelmet title={'Send a Message | Bank Enterprise'} />

      <Box
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        alignContent={'center'}
        justifyContent={'flex-start'}
        boxShadow={'3px 3px 200px #000 inset'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
        background={
          websiteSettings?.background_images?.other_page_bg_type === 'color'
            ? websiteSettings?.background_images?.other_page_bg_value
            : `url('${websiteSettings?.background_images?.other_page_bg_value}')`
        }
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
      >
        <Box
          flexDir={'row'}
          display={'flex'}
          gap={[4, 4, 5, 5, 5]}
          px={[5, 5, 5, 10, 10]}
          mt={[0, 0, 10, 10, 10]}
          justifyContent={'flex-end'}
        >
          <Box
            w={'100%'}
            display={'flex'}
            flexDir={'column'}
            gap={[5, 5, 5, 10, 10]}
            px={[0, 0, 0, 0, 0, 10]}
            mb={[0, 0, 0, 0, 0, 20]}
          >
            <Box
              w={'100%'}
              flexDir={'row'}
              display={'flex'}
              alignItems={'center'}
              alignContent={'center'}
              justifyContent={'center'}
              marginTop={[10, 10, 0, 0, 0]}
            >
              <Text
                width={'100%'}
                color={'black'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
                flex={[
                  '0 0 12rem',
                  '0 0 12rem',
                  '0 0 12rem',
                  '0 0 13rem',
                  '0 0 16rem',
                ]}
              >
                Send us a message
              </Text>
              <Divider
                ml={4}
                w={'200%'}
                borderWidth={3}
                borderRadius={5}
                borderColor={'#E76D0C'}
                orientation={'horizontal'}
              />
            </Box>

            <Box
              gap={'1.5rem'}
              display={'flex'}
              borderRadius={10}
              p={[5, 5, 5, 10, 10]}
              flexDirection={'column'}
              backgroundColor={'white'}
              boxShadow={'0 50px 200px #000'}
            >
              <FormControl>
                <FormLabel fontSize={[12, 12, 14, 16, 16]}>Name</FormLabel>
                <Input
                  type='text'
                  name='name'
                  value={formData?.name}
                  onChange={handleChange}
                  fontSize={[12, 12, 14, 16, 16]}
                />
              </FormControl>

              <FormControl>
                <FormLabel fontSize={[12, 12, 14, 16, 16]}>Email</FormLabel>
                <Input
                  type='email'
                  name='email'
                  value={formData?.email}
                  onChange={handleChange}
                  fontSize={[12, 12, 14, 16, 16]}
                />
              </FormControl>

              <FormControl>
                <FormLabel fontSize={[12, 12, 14, 16, 16]}>Title</FormLabel>
                <Input
                  type='text'
                  name='title'
                  value={formData?.title}
                  onChange={handleChange}
                  fontSize={[12, 12, 14, 16, 16]}
                />
              </FormControl>

              <FormControl>
                <FormLabel fontSize={[12, 12, 14, 16, 16]}>Message</FormLabel>
                <Textarea
                  rows={10}
                  name='content'
                  onChange={handleChange}
                  value={formData?.content}
                  fontSize={[12, 12, 14, 16, 16]}
                />
              </FormControl>

              <Box
                gap={'2rem'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'flex-end'}
              >
                <Text fontWeight={'500'} className={message}>
                  {message}
                </Text>

                <Button
                  color={'#fff'}
                  onClick={handleSend}
                  backgroundColor={'#E76D0C'}
                  fontSize={[12, 12, 14, 16, 16]}
                  width={['8rem', '8rem', '10rem', '10rem', '10rem']}
                >
                  Send Message
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Complaint;
