import { Box, Button, Divider, Grid, GridItem, Text } from "@chakra-ui/react";
import React from "react";

import { Link } from "react-router-dom";
import { AiFillBank } from "react-icons/ai";

const data = [
  {
    title: "Enterprise Loans",
    link: "/Business/enterprise",
  },
  {
    title: "Consumer Loans",
    link: "/Business/consumer",
  },
  {
    title: "Deposits",
    link: "/Business/deposits",
  },
  {
    title: "Other Loans",
    link: "/Business/other",
  },
];

const MainNav = () => {
  return (
    <>
      <Box
        w={"100%"}
        h={"768px"}
        backgroundColor={"#e6e6e6"}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        display={"flex"}
        flexDir={"column"}
        justifyContent={"center"}
        boxShadow={"3px 3px 200px #000 inset"}
      >
        <Box
          display={"flex"}
          flexDir={"row"}
          justifyContent={"center"}
          w={"100%"}
        >
          <Box display={"flex"} flexDir={"column"} w={"100%"}>
            <Box
              display={"flex"}
              flexDir={"row"}
              alignItems={"center"}
              alignContent={"center"}
              w={"100%"}
              justifyContent={"center"}
            >
              <Text fontFamily={"Poppins"} color={"#E76D0C"} fontSize={25}>
                Main Menu
              </Text>
              <Divider
                orientation={"horizontal"}
                borderColor={"#E76D0C"}
                borderWidth={3}
                borderRadius={5}
                w={"45%"}
                ml={4}
              />
            </Box>
            <Box display={"flex"} justifyContent={"center"} my={5} w={"100%"}>
              <Grid templateColumns={"repeat(2, 1fr)"} gap={5}>
                {data.map((item) => {
                  return (
                    <GridItem w="100%">
                      <Link to={item.link}>
                        <Button
                          p={10}
                          display={"flex"}
                          flexDir={"row"}
                          alignItems={"center"}
                          justifyContent={"flex-start"}
                          gap={2}
                          m={0}
                          color={"#E76D0C"}
                          w={"100%"}
                          _hover={{
                            backgroundColor: "#E76D0c",
                            transitionDelay: "1s ease-in",
                            color: "white",
                          }}
                        >
                          <AiFillBank size={32} />
                          <Text fontFamily={"Poppins-Bold"} fontSize={25}>
                            {item.title}
                          </Text>
                        </Button>
                      </Link>
                    </GridItem>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MainNav;
