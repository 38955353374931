import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Td,
  Tr,
  Th,
  Box,
  Text,
  Table,
  Tbody,
  Thead,
  Button,
  Divider,
  Textarea,
  TableContainer,
} from '@chakra-ui/react';

import ReactHelmet from '../../../../components/ReactHelmet';

const News = () => {
  const [pageData, setPageData] = useState([]);
  const [viewContent, setViewContent] = useState(null);
  const [websiteSettings, setWebsiteSettings] = useState([]);

  const formatDate = (data) => {
    const date = new Date(data); // Replace this with your date object

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    return date.toLocaleString('en-US', options);
  };

  useEffect(() => {
    const getAdvisories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/advisories`
        );

        setPageData(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getWebsiteData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/website-settings`
        );

        setWebsiteSettings(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getWebsiteData();
    getAdvisories();
  }, []);

  useEffect(() => {
    console.log('viewContent', viewContent);
  }, [viewContent]);

  return (
    <>
      <ReactHelmet title={'News and Advisories | Bank Enterprise'} />

      <Box
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        alignContent={'center'}
        justifyContent={'center'}
        boxShadow={'3px 3px 200px #000 inset'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
        background={
          websiteSettings?.background_images?.other_page_bg_type === 'color'
            ? websiteSettings?.background_images?.other_page_bg_value
            : `url('${websiteSettings?.background_images?.other_page_bg_value}')`
        }
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
      >
        <Box
          w={'100%'}
          display={'flex'}
          flexDir={'column'}
          gap={[0, 0, 0, 10, 10]}
          px={[0, 0, 0, 0, 0, 10]}
          mb={[0, 0, 0, 0, 0, 20]}
        >
          <Box
            mb={10}
            px={10}
            gap={10}
            w={'100%'}
            display={'flex'}
            flexDir={'column'}
          >
            <Box
              w={'100%'}
              flexDir={'row'}
              display={'flex'}
              textAlign={'start'}
              alignItems={'center'}
              alignContent={'flex-start'}
              marginTop={[10, 10, 0, 0, 0]}
              justifyContent={'flex-start'}
            >
              <Text
                width={'100%'}
                color={'black'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
                flex={[
                  '0 0 10.5rem',
                  '0 0 10.5rem',
                  '0 0 10.5rem',
                  '0 0 12rem',
                  '0 0 14rem',
                ]}
              >
                News <span style={{ color: '#E76D0C' }}>/ Advisories</span>
              </Text>

              <Divider
                ml={4}
                w={'70%'}
                borderWidth={3}
                borderRadius={5}
                borderColor={'#E76D0C'}
                orientation={'horizontal'}
              />
            </Box>

            <Box
              p={5}
              gap={5}
              display={'flex'}
              borderRadius={10}
              flexDir={'column'}
              backgroundColor={'white'}
              boxShadow={'0 50px 200px #000'}
            >
              {viewContent === null && (
                <TableContainer>
                  <Table className='news-table'>
                    <Thead>
                      <Tr>
                        <Th>Title</Th>
                        <Th>Description</Th>
                        <Th>Date</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {pageData.map((dd) => {
                        return (
                          <Tr
                            cursor={'pointer'}
                            borderBottomWidth={1}
                            borderColor={'black'}
                            onClick={() => {
                              setViewContent(dd);
                            }}
                          >
                            <Td
                              fontSize={8}
                              borderWidth={0}
                              alignItems={'start'}
                              fontFamily={'Poppins'}
                              whiteSpace={'break-spaces'}
                              padding={[2, 2, 5, 5, 5]}
                            >
                              {dd?.title}
                            </Td>
                            <Td
                              fontSize={8}
                              borderWidth={0}
                              fontFamily={'Poppins'}
                              padding={[2, 2, 5, 5, 5]}
                              whiteSpace={'break-spaces'}
                            >
                              {dd?.content}
                            </Td>
                            <Td
                              fontSize={8}
                              borderWidth={0}
                              display={'flex'}
                              alignItems={'start'}
                              fontFamily={'Poppins'}
                              whiteSpace={'break-spaces'}
                              padding={[2, 2, 5, 5, 5]}
                            >
                              {formatDate(dd?.date)}
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}

              {viewContent && viewContent !== null && (
                <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
                  <Button
                    color={'#fff'}
                    width={'60px'}
                    backgroundColor={'#E76D0C'}
                    onClick={() => setViewContent(null)}
                  >
                    Back
                  </Button>

                  <Text
                    fontSize={20}
                    marginTop={'1rem'}
                    fontWeight={'600'}
                    fontFamily={'Poppins'}
                  >
                    {viewContent?.title}
                    <span style={{ display: 'block', fontSize: '14px' }}>
                      {formatDate(viewContent?.date)}
                    </span>
                  </Text>

                  <Textarea
                    readOnly
                    rows={25}
                    padding={0}
                    border={'none'}
                    borderRadius={0}
                    marginTop={'1rem'}
                  >
                    {viewContent?.content}
                  </Textarea>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default News;
