import { useQuery } from 'react-query';
import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import {
  Box,
  Image,
  Divider,
  Text,
  useToast,
  Textarea,
} from '@chakra-ui/react';

import TopLinks from '../../../components/TopLinks';
import { getWebsiteSettings } from '../actions/actions';
import CustomTextArea from '../../../components/TextArea';
import ReactHelmet from '../../../components/ReactHelmet';
import CustomTextareaNew from '../../../components/TextAreaNew';

import './styles.scss';

const PageOne = () => {
  const { data, isLoading, isError, error } = useQuery(
    'websiteSettings',
    getWebsiteSettings
  );

  const toast = useToast();

  const [pageData, setPageData] = useState(null);

  const handleAbout = () => {
    if (data) {
      setPageData(data.data.data);
    }
    if (isError) {
      toast({
        title: 'Error',
        status: 'Error',
        duration: 10000,
        description: error,
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleAbout();
    }, 0);
  });

  console.log('pageData', pageData);

  return (
    <>
      <ReactHelmet title={'About Us | Bank Enterprise'} />

      <Box
        gap={5}
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        overflowY={'hidden'}
        justifyContent={'start'}
        boxShadow={'3px 3px 200px #000 inset'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
        background={
          pageData?.background_images?.other_page_bg_type === 'color'
            ? pageData?.background_images?.other_page_bg_value
            : `url('${pageData?.background_images?.other_page_bg_value}')`
        }
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
      >
        <Box
          flexDir={'row'}
          display={'flex'}
          gap={[4, 4, 5, 5, 5]}
          px={[5, 5, 5, 10, 10]}
          mt={[0, 0, 10, 10, 10]}
          justifyContent={'flex-end'}
        >
          <TopLinks />
        </Box>
        <Box
          w={'100%'}
          flexDir={'row'}
          display={'flex'}
          justifyContent={'center'}
          gap={['1rem', '1rem', '2rem', '2rem', '2rem']}
          px={['2rem', '2rem', '2rem', '2rem', '4rem']}
          flexDirection={[
            'column-reverse',
            'column-reverse',
            'row',
            'row',
            'row',
          ]}
        >
          {pageData && pageData?.about_us_details && (
            <Box
              w={'100%'}
              flex={'2'}
              display={'flex'}
              flexDir={'column'}
              mb={[0, 0, 0, 0, 0, 20]}
            >
              <Box
                w={'100%'}
                gap={'1rem'}
                flexDir={'row'}
                display={'flex'}
                textAlign={'start'}
                alignItems={'center'}
                alignContent={'flex-start'}
                justifyContent={'flex-start'}
              >
                <Text
                  fontSize={25}
                  color={'white'}
                  w={'fit-content'}
                  fontFamily={'Poppins'}
                >
                  About
                </Text>
                <Divider
                  borderWidth={3}
                  borderRadius={5}
                  borderColor={'#E76D0C'}
                  orientation={'horizontal'}
                  w={['80%', '80%', '80%', '80%', '80%', '100%']}
                />
              </Box>
              <Text fontFamily={'Poppins'} color={'#E76D0C'} fontSize={25}>
                {pageData?.about_us_details?.subtitle}
              </Text>

              <CustomTextareaNew
                my={3}
                readOnly
                padding={0}
                fontSize={12}
                color={'#fff'}
                border={'none'}
                fontFamily={'Poppins'}
                className={'about-us-textarea'}
              >
                {pageData?.about_us_details?.content}
              </CustomTextareaNew>
            </Box>
          )}

          <Box flex={'1'}>
            <Carousel
              autoPlay
              infiniteLoop
              swipeable={true}
              interval={5000}
              showStatus={false}
              showArrows={false}
              emulateTouch={true}
              showIndicators={false}
              // className='carousel-container-2'
            >
              {pageData &&
                pageData.about_us_details?.images?.length > 0 &&
                pageData.about_us_details.images.map((dd) => {
                  return (
                    <Image
                      w={'100%'}
                      borderRadius={10}
                      src={dd?.img_path}
                      mt={['1rem', '2rem', '0', '0', '0']}
                      height={['14rem', '14rem', 'auto', 'auto', 'auto']}
                      objectFit={[
                        'contain',
                        'contain',
                        'cover',
                        'cover',
                        'cover',
                      ]}
                    />
                  );
                })}
            </Carousel>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PageOne;
