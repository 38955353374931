import axios from 'axios';
import parse from 'html-react-parser';
import { useQuery } from 'react-query';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { Box, Divider, Image, Text, useToast } from '@chakra-ui/react';

import TopLinks from '../../../components/TopLinks';
import ReactHelmet from '../../../components/ReactHelmet';
import { getBusinessBankings } from '../../About/actions/actions';

import car from '../../../assets/car.jpg';
import left from '../../../assets/left.png';
import right from '../../../assets/right.png';

const PageFour = () => {
  const { data, isLoading, isError, error } = useQuery(
    'businessBankings',
    getBusinessBankings
  );

  const navigate = useNavigate();

  const toast = useToast();

  const [pageData, setPageData] = useState(null);
  const [websiteSettings, setWebsiteSettings] = useState([]);

  const handlePersonalBanking = () => {
    if (data) {
      setPageData(data.data.data);
    }
    if (isError) {
      toast({
        title: 'Error',
        status: 'Error',
        duration: 10000,
        description: error,
        position: 'top-right',
      });
    }
  };

  const handleRedirect = () => {
    navigate('/business-banking');
  };

  const removeQuotesAndParse = (string) => {
    if (!string) return;

    const newString = string.replace(/"/g, '');

    return parse(newString);
  };

  useEffect(() => {
    setTimeout(() => {
      handlePersonalBanking();
    }, 0);
  });

  useEffect(() => {
    const getWebsiteData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/website-settings`
        );

        setWebsiteSettings(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getWebsiteData();
  }, []);

  return (
    <>
      <ReactHelmet title={'We Also Offers | Bank Enterprise'} />

      <Box
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        justifyContent={'space-between'}
        boxShadow={'3px 3px 200px #000 inset'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
        background={
          websiteSettings?.background_images?.other_page_bg_type === 'color'
            ? websiteSettings?.background_images?.other_page_bg_value
            : `url('${websiteSettings?.background_images?.other_page_bg_value}')`
        }
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
      >
        <Box
          flexDir={'row'}
          display={'flex'}
          gap={[4, 4, 5, 5, 5]}
          px={[5, 5, 5, 10, 10]}
          mt={[0, 0, 10, 10, 10]}
          justifyContent={'flex-end'}
        >
          <TopLinks />
        </Box>
        <Box
          px={10}
          w={'100%'}
          flexDir={'row'}
          display={'flex'}
          justifyContent={'center'}
        >
          <Box
            w={'100%'}
            display={'flex'}
            flexDir={'column'}
            px={[0, 0, 0, 0, 0, 10]}
            mb={[0, 0, 0, 0, 0, 20]}
          >
            <Box
              w={'100%'}
              gap={'1rem'}
              flexDir={'row'}
              display={'flex'}
              flexWrap={'wrap'}
              alignItems={'center'}
              alignContent={'center'}
              justifyContent={'center'}
            >
              <Text
                color={'white'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
              >
                We also
              </Text>
              <Text
                color={'#E76D0C'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
              >
                offer
              </Text>
              <Divider
                ml={6}
                w={'83%'}
                borderWidth={3}
                borderRadius={5}
                borderColor={'#E76D0C'}
                orientation={'horizontal'}
              />
            </Box>
            <Carousel
              autoPlay
              swipeable
              emulateTouch
              infiniteLoop
              interval={3000}
              showArrows={false}
              showStatus={false}
              showIndicators={false}
            >
              {pageData &&
                pageData.length > 0 &&
                pageData.map((dd) => {
                  return (
                    <Box
                      h={60}
                      mt={10}
                      display={'flex'}
                      borderRadius={10}
                      cursor={'pointer'}
                      onClick={handleRedirect}
                      backgroundColor={'white'}
                      justifyContent={'space-between'}
                    >
                      <Box
                        display={'flex'}
                        flexDir={'column'}
                        textAlign={'start'}
                        mt={[5, 5, 10, 10, 10]}
                        px={[5, 5, 10, 10, 10]}
                        minWidth={['10rem', '10rem', '10rem', '20rem', '20rem']}
                      >
                        <Text
                          my={3}
                          color={'#E76D0C'}
                          fontFamily={'Poppins'}
                          fontSize={[20, 20, 25, 32, 32]}
                        >
                          {dd.title}
                        </Text>
                        {/* <Text fontFamily={'Poppins'} my={3} fontSize={12}>
                          {dd.description}
                        </Text> */}
                        <div
                          style={{
                            fontSize: '12px',
                            color: 'gray',
                            fontFamily: 'Poppins',
                          }}
                          className='universal-description'
                        >
                          {removeQuotesAndParse(dd.description)}
                        </div>
                      </Box>

                      <Image
                        src={dd?.img_path}
                        objectFit={'cover'}
                        borderTopRightRadius={10}
                        borderBottomEndRadius={10}
                        w={['40%', '40%', '40%', '40%', '40%', '20%']}
                      />
                    </Box>
                  );
                })}
            </Carousel>
          </Box>
        </Box>
        <Box
          my={10}
          px={10}
          display={'flex'}
          letterSpacing={1}
          gap={[0, 2, 3, 3, 3]}
          alignItems={'center'}
          justifyContent={'flex-end'}
          flexDir={['column', 'column', 'row', 'row', 'row']}
        >
          <Text
            color={'#E76D0C'}
            fontFamily={'Poppins'}
            fontSize={[20, 20, 20, 30, 30]}
          >
            OTHER LOANS
          </Text>

          <Box display={'flex'} letterSpacing={1} gap={[2, 2, 3, 3, 3]}>
            <Link to={'/products'}>
              <Image
                src={left}
                mt={[10, 10, 0, 0, 0]}
                w={['30px', '30px', '30px', '40px', '40px']}
              />
            </Link>
            <Link to={'/services'}>
              <Image
                src={right}
                mt={[10, 10, 0, 0, 0]}
                w={['30px', '30px', '30px', '40px', '40px']}
              />
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PageFour;
