import axios from 'axios';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Box, Divider, Image, Text, useToast } from '@chakra-ui/react';

import TopLinks from '../../../components/TopLinks';
import ReactHelmet from '../../../components/ReactHelmet';
import { getFeedbacks } from '../../About/actions/actions';

import left from '../../../assets/left.png';

const PageSeven = () => {
  const { data, isLoading, isError, error } = useQuery(
    'feedbacks',
    getFeedbacks
  );

  const toast = useToast();

  const [pageData, setPageData] = useState(null);
  const [websiteSettings, setWebsiteSettings] = useState([]);

  const handleServices = () => {
    if (data) {
      setPageData(data.data.data);
    }
    if (isError) {
      toast({
        title: 'Error',
        status: 'Error',
        duration: 10000,
        description: error,
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleServices();
    }, 0);
  });

  useEffect(() => {
    const getWebsiteData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/website-settings`
        );

        setWebsiteSettings(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getWebsiteData();
  }, []);

  return (
    <>
      <ReactHelmet title={'Feedbacks | Bank Enterprise'} />

      <Box
        h={'auto'}
        display={'flex'}
        flexDir={'column'}
        justifyContent={'space-between'}
        boxShadow={'3px 3px 200px #000 inset'}
        padding={['10px 0', '10px 0', '2rem 0', '2rem 0', '2rem 0']}
        w={[
          '100%',
          '100%',
          'calc(100% - 200px)',
          'calc(100% - 304px)',
          'calc(100% - 304px)',
        ]}
        background={
          websiteSettings?.background_images?.other_page_bg_type === 'color'
            ? websiteSettings?.background_images?.other_page_bg_value
            : `url('${websiteSettings?.background_images?.other_page_bg_value}')`
        }
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
      >
        <Box
          flexDir={'row'}
          display={'flex'}
          gap={[4, 4, 5, 5, 5]}
          px={[5, 5, 5, 10, 10]}
          mt={[0, 0, 10, 10, 10]}
          justifyContent={'flex-end'}
        >
          <TopLinks />
        </Box>
        <Box
          px={10}
          w={'100%'}
          flexDir={'row'}
          display={'flex'}
          justifyContent={'center'}
        >
          <Box display={'flex'} flexDir={'column'} w={'100%'} mb={10}>
            <Box
              w={'100%'}
              gap={'1rem'}
              flexDir={'row'}
              display={'flex'}
              flexWrap={'wrap'}
              alignItems={'center'}
              alignContent={'center'}
              justifyContent={'center'}
            >
              <Text
                color={'white'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
              >
                What our
              </Text>
              &nbsp;
              <Text
                color={'#E76D0C'}
                fontFamily={'Poppins'}
                fontSize={[18, 18, 18, 20, 25]}
              >
                Clients Say
              </Text>
              <Divider
                ml={4}
                w={'45%'}
                borderWidth={3}
                borderRadius={5}
                borderColor={'#E76D0C'}
                orientation={'horizontal'}
              />
            </Box>
            <Box
              my={10}
              gap={15}
              w={'100%'}
              flexWrap={'wrap'}
              display={'inline-flex'}
              justifyContent={'center'}
            >
              {pageData &&
                pageData.length > 0 &&
                pageData.map((dd) => {
                  return (
                    <Box
                      p={5}
                      gap={5}
                      display={'flex'}
                      borderRadius={10}
                      flexDirection={'column'}
                      backgroundColor={'white'}
                      w={[250, 250, 250, 300, 300]}
                      justifyContent={'flex-start'}
                      boxShadow={'10px 20px 40px #9c9c9c'}
                    >
                      <Box
                        gap={5}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'start'}
                        flexDir={['column', 'column', 'column', 'row', 'row']}
                      >
                        <Image
                          w={'100%'}
                          maxW={'10rem'}
                          height={'10rem'}
                          borderRadius={10}
                          src={dd?.img_path}
                          objectFit={'cover'}
                        />
                        <Text
                          fontSize={15}
                          color={'#E76D0C'}
                          fontWeight={'medium'}
                          fontFamily={'Poppins'}
                        >
                          {dd?.position}
                        </Text>
                      </Box>

                      <Text
                        fontSize={15}
                        fontWeight={'medium'}
                        fontFamily={'Poppins'}
                      >
                        {dd?.name}
                      </Text>
                      <Text fontFamily={'Poppins'} color={'gray'} fontSize={12}>
                        {dd?.feedback}
                      </Text>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
        <Box
          my={10}
          px={10}
          display={'flex'}
          letterSpacing={1}
          gap={[0, 2, 3, 3, 3]}
          alignItems={'center'}
          justifyContent={'flex-end'}
          flexDir={['column', 'column', 'row', 'row', 'row']}
        >
          <Text
            color={'#E76D0C'}
            fontFamily={'Poppins'}
            fontSize={[20, 20, 20, 30, 30]}
          >
            CUSTOMER FEEDBACK
          </Text>

          <Box display={'flex'} letterSpacing={1} gap={[2, 2, 3, 3, 3]}>
            <Link to={'/milestones'}>
              <Image
                src={left}
                mt={[10, 10, 0, 0, 0]}
                w={['30px', '30px', '30px', '40px', '40px']}
              />
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PageSeven;
