import {
  Box,
  Card,
  CardBody,
  Divider,
  Grid,
  GridItem,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

// import { AiFillCar } from "react-icons/ai";
// import { FaHouseUser, FaShuttleVan, FaTruck } from "react-icons/fa";
import { useQuery } from "react-query";
import { getOtherLoans } from "../actions/actions";
import { Circles } from "react-loader-spinner";

// const data = [
//   {
//     icon: <AiFillCar />,
//     type: "Car Loan",
//     desc: "Financing for the acquisition of brand new service vehicle that will be used for personal or business operations",
//     image: require("../../../assets/loans/car_loan.jpg"),
//   },
//   {
//     icon: <FaTruck />,
//     type: "Truck Loan",
//     desc: "Financing for the acquisition of TRUCKS for business operation",
//     image: require("../../../assets/loans/truck_loan.jpg"),
//   },
//   {
//     icon: <FaShuttleVan />,
//     type: "PUV Loan",
//     desc: "Financing for the acquisition of public utility vehicles for operating taxi or van rental business",
//     image: require("../../../assets/loans/puv_loan.jpg"),
//   },
//   {
//     icon: <FaHouseUser />,
//     type: "Housing Loan",
//     desc: "Financing and re-financing of house construction and/or lot acquisition",
//     image: require("../../../assets/loans/house_loan.jpg"),
//   },
// ];

const Other = () => {
  const toast = useToast();
  const { data, isLoading, isError, error } = useQuery(
    "packages",
    getOtherLoans
  );
  const [others, setOthers] = useState([]);

  const handleOthers = () => {
    if (data) {
      setOthers(data.data.packages);
    }
    if (data === null) {
      toast({
        title: "No data!",
        description: "No Data Received",
        status: "warning",
        duration: 10000,
        position: "top-right",
      });
    }
    if (isError) {
      toast({
        title: "Error",
        description: error,
        status: "Error",
        duration: 10000,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleOthers();
    }, 1000);
  });
  return (
    <>
      <Box
        w={"80%"}
        h={"768px"}
        backgroundColor={"#e6e6e6"}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        display={"flex"}
        flexDir={"column"}
        justifyContent={"space-between"}
        boxShadow={"3px 3px 200px #000 inset"}
      >
        <Box
          display={"flex"}
          flexDir={"row"}
          justifyContent={"center"}
          w={"100%"}
          p={10}
        >
          <Box display={"flex"} flexDir={"column"} w={"100%"}>
            <Box
              display={"flex"}
              flexDir={"row"}
              alignItems={"center"}
              alignContent={"center"}
              w={"100%"}
              justifyContent={"center"}
            >
              <Text fontFamily={"Poppins"} color={"#E76D0C"} fontSize={25}>
                Other Loans
              </Text>
              <Divider
                orientation={"horizontal"}
                borderColor={"#E76D0C"}
                borderWidth={3}
                borderRadius={5}
                w={"45%"}
                ml={4}
              />
            </Box>
            <Box
              display={"inline-flex"}
              justifyContent={"center"}
              gap={15}
              my={5}
              w={"100%"}
              flexWrap={"wrap"}
            >
              <Grid templateColumns={"repeat(2, 1fr)"} gap={5}>
                {isLoading ? (
                  <Circles
                    height="80"
                    width="80"
                    color="#E76D0C"
                    ariaLabel="circles-loading"
                    visible={true}
                  />
                ) : (
                  <>
                    {others?.map((item) => {
                      return (
                        <GridItem w="100%">
                          <Card p={0} w={"220px"}>
                            <CardBody
                              display={"flex"}
                              flexDir={"column"}
                              textAlign={"start"}
                            >
                              <Image src={item.media} />
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                gap={2}
                                fontSize={16}
                                color={"#E76D0C"}
                              >
                                {/* {item.icon} */}
                                <Text fontFamily={"Poppins-Bold"}>
                                  {item.title}
                                </Text>
                              </Box>
                              <Box
                                display={"flex"}
                                flexDir={"column"}
                                justifyContent={"start"}
                                alignItems={"start"}
                              >
                                <Text fontFamily={"Poppins"} fontSize={10}>
                                  {item?.description}
                                </Text>
                              </Box>
                            </CardBody>
                          </Card>
                        </GridItem>
                      );
                    })}
                  </>
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Other;
