import React from 'react';
import { Route, Routes } from 'react-router-dom';

import News from './components/news/news';
import PageOne from './components/pageOne';
import PageTwo from './components/pageTwo';
import PageFour from './components/pageFour';
import PageThree from './components/pageThree';
import AboutMenu from './components/aboutMenu';
import Careers from './components/careers/careers';
import MainNav from './components/branchAtm/mainNav';
import AtmLocator from './components/branchAtm/atmLocator';
import Properties from './components/properties/properties';
import BranchLocator from './components/branchAtm/branchLocator';
import Terms from './components/corporateInformation/terms/terms';
import ContactUs from './components/corporateInformation/contact/contactUs';
import CorporateMenu from './components/corporateInformation/corporateMenu';
import Complaint from './components/corporateInformation/complaint/complaint';
import PrivacyStatement from './components/corporateInformation/privacyStatement/privacyStatement';

import 'react-multi-carousel/lib/styles.css';

const About = () => {
  return (
    <Routes>
      <Route index element={<AboutMenu />} />
      <Route path={'/news'} element={<News />} />
      <Route path={'/terms'} element={<Terms />} />
      <Route path={'/desc'} element={<PageOne />} />
      <Route path={'/atm'} element={<AtmLocator />} />
      <Route path={'/careers'} element={<Careers />} />
      <Route path={'/reports'} element={<PageFour />} />
      <Route path={'/directors'} element={<PageTwo />} />
      <Route path={'/message'} element={<Complaint />} />
      <Route path={'/atmbranch'} element={<MainNav />} />
      <Route path={'/info'} element={<CorporateMenu />} />
      <Route path={'/contactUs'} element={<ContactUs />} />
      <Route path={'/executives'} element={<PageThree />} />
      <Route path={'/branch'} element={<BranchLocator />} />
      <Route path={'/properties'} element={<Properties />} />
      <Route path={'/privacy'} element={<PrivacyStatement />} />
    </Routes>
  );
};

export default About;
